import { useAsyncStatus } from "@src/hex/use_async_status";
import { useConversationMediator } from "@src/providers/ConversationMediatorProvider";

export function useFailedMessageStatus() {
  const conversationMediator = useConversationMediator();

  // const sendMessageStatus = useAsyncValue(
  const sendMessageStatus = useAsyncStatus(
    conversationMediator.sendMessageRunner
  );

  return sendMessageStatus.isError;
}
