// General
import { LayoutChangeEvent } from "react-native";

// Utils/Hooks
import { useConversationMediator } from "@src/providers/ConversationMediatorProvider";
import { useAsyncValue } from "@src/hex/use_async_value";

export const useWidgetHeight = () => {
  const chatManager = useConversationMediator();

  const widgetHeight = useAsyncValue(chatManager.widgetHeight);

  const setHeight = chatManager.setWidgetHeight;

  const onWidgetLayout = (event: LayoutChangeEvent) => {
    setHeight(event.nativeEvent.layout.height);
  };

  return { widgetHeight, onWidgetLayout };
};
