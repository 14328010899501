// Utils/Hooks
import { useConversationMediator } from "@src/providers/ConversationMediatorProvider";
import { getSessionColors } from "@src/utils";

// Types/Constants
import { ColorProperties } from "@src/mediators/ChatClientApiAdapter/apiTypes";
import { IPingResponse } from "@src/mediators/ChatClientApiAdapter/apiTypes";
import { useAsyncValue } from "@src/hex/use_async_value";

// Will return a default if no config. Although the app shouldn't load unless we have one.
export function useColorProperties(): ColorProperties {
  const chatManager = useConversationMediator();
  // TODO this is sometimes fails, it does not get set and causes anything using useColorProperties to get an undefined ColorProperties object
  const campaignData = (
    useAsyncValue(chatManager.widgetConfigurationRunner) as IPingResponse
  )?.campaignData;

  // get from storage if it exists
  const colors = getSessionColors();
  if (colors) {
    return JSON.parse(colors);
  }

  // otherwise fallback
  return (
    campaignData?.colorProperties ?? {
      mainColor: "#669EB4",
      headingColor: "#ffffff",
      paragraphColor: "#000000",
    }
  );
}
