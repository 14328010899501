import React, { useCallback } from "react";

import { resizeIframeToHtmlHeight } from "@src/components/flows/flow_message/utils";
import { useAsyncValue } from "@src/hex/use_async_value";
import { FlowMessageDomain } from "@src/mediators/ConversationFlow/flow_message_domain";
import { WebViewWeb as WebView } from "@src/components/flows/flow_message/webview";

interface WebViewWebProps {
  messageId: string;
  flowDomain: FlowMessageDomain;
}

export const WebViewWeb = ({ flowDomain, messageId }: WebViewWebProps) => {
  const html = useAsyncValue(flowDomain.presenter.updatedHtml);

  // Util to keep the iframe height in sync with the content
  const iFrameResizer = useCallback(
    () => resizeIframeToHtmlHeight(messageId),
    []
  );

  const handleWebViewMessage = async (event) => {
    if (event.nativeEvent.data.formValues) {
      try {
        await flowDomain.sendResponse(
          JSON.stringify(event.nativeEvent.data.formValues)
        );
      } catch (e) {
        console.error(e);
      }
    } else {
      return;
    }
  };

  return (
    <WebView
      source={{ html: html }}
      onMessage={handleWebViewMessage}
      style={{
        display: "flex",
        flexDirection: "column",
      }}
      id={messageId}
      onLoad={iFrameResizer}
    />
  );
};
