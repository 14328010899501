// General
import { ObservableValue } from "@src/hex/observable_value";

// Utils/Hooks
import {
  conversationHasEnded,
  clearUserSessionData,
  setUserSessionData,
  setAgentMessageIdSessionData,
  getAgentMessageIdSessionData,
  clearAgentMessageIdSessionData,
  setSessionColors,
} from "@src/utils";
import { unpackChatMessage } from "@src/mediators/ChatClientApiAdapter/unpackUtils";

// Types/Constants
import {
  IProtoChatMessage,
  IOmniMessagePayload,
  ChatSenderType,
  IOmniTextMessage,
  MessageFormat,
  DataMessage,
} from "@src/mediators/types/IProtoChatMessage";
import { IMessagePayload } from "@src/mediators/types/IMessagePayload";
import { IChatClientApi } from "@src/mediators/types/IChatClientApi";
import { IUserData } from "@src/mediators/types/IUserData";
import {
  ConversationStates,
  SECOND,
  StatusCodes,
  maxCharCount,
} from "@src/constants";
import {
  ConversationConnectionDomain,
  ConversationConnectionDomainPort,
} from "@src/mediators/ConversationConnection/ConversationConnectionDomain";
import { v4 as uuid } from "uuid";
import { takeFirst } from "@src/mediators/ConversationConnection/streamAction";
import { ObservableArray } from "@src/hex/observable_array";
import { AsyncActionRunner } from "@src/hex/async_action_runner";
import { IConversationCustomerInformation } from "@src/mediators/types/IProtoConversationCustomerInformation";
import { IPingResponse } from "@src/mediators/ChatClientApiAdapter/apiTypes";
import {
  FlowMessageDomain,
  FlowMessageDomainContext,
} from "@src/mediators/ConversationFlow/flow_message_domain";
import {
  CustomerCollectedData,
  KnownCustomerMetaData,
} from "@src/mediators/types/customer_collected_data";

export interface ISendMessageRequest {
  payload: IOmniMessagePayload;
  uiReferenceId: string;
  customerInformation: IConversationCustomerInformation;
}

export type FetchConfigErrorType =
  | ConversationStates.INVALID_CAMPAIGN_EXPIRED
  | ConversationStates.OPERATING_HOURS_MISS
  | ConversationStates.NO_WIDGET_CONFIG_FOUND;

export const errorMessages = {
  [ConversationStates.INVALID_CAMPAIGN_EXPIRED]: "Campaign is no longer valid",
  [ConversationStates.OPERATING_HOURS_MISS]: "No availability at this hour",
  [ConversationStates.NO_WIDGET_CONFIG_FOUND]:
    "Failed to load widget. Please try refreshing the page.",
};

export interface ConversationMediatorContext {
  api: IChatClientApi;
  sessionConversationId?: string;
  sessionUserData?: IUserData;
  sessionSlaT11Timer?: number;

  dormantResubscribeTime?: number;
  idleCountdownThreshold?: number;
}

class ConversationMediator {
  private _api: IChatClientApi;

  // Id unique to the conversation
  private _conversationApiKey = new ObservableValue<string | null>(null);

  // Retry when disconnect occurs
  private _retryInterval = 0;

  // contains the last status code from when the connection was closed. Can be helpful for debugging and unit testing.
  private _lastStatusCode = new ObservableValue<{
    code: StatusCodes;
    reason: string;
  } | null>(null);

  private _customerIsTyping: ObservableValue<boolean> = new ObservableValue(
    false
  );

  private _customerIsTypingTimeout = 0;
  private _agentIsTypingTimeoutId = 0;

  // track chat reconnection for recalculating the idle timer
  private _chatReconnected = false;

  // used if chat is reconnected (browser is refreshed) to recalculate the idle customer time and create a new timer
  private _idleCustomerReconnectionElapsedTime: ObservableValue<number> =
    new ObservableValue(0);

  // Retry when conversation is unassigned
  private _queuePositionCheckInterval = 0;

  private _previousWaitingQueue: string | undefined = undefined;

  private _socketConnection: ConversationConnectionDomainPort;

  public chatMessages = new ObservableArray<IMessagePayload>();

  public fetchConfigurationError =
    new ObservableValue<null | FetchConfigErrorType>(null);

  public conversationState = new ObservableValue<ConversationStates>(
    ConversationStates.IDLE
  );

  // Request is made before the modal button is rendered.
  public widgetConfigurationRunner =
    new AsyncActionRunner<IPingResponse | void>(undefined);

  // Shows the initial connection. Subsequent connections attempts will be monitored internally.
  public initialConnectionRunner =
    new AsyncActionRunner<WebSocket | void | null>(null);

  public user = new ObservableValue<IUserData | null>(null);

  public failedInitialMessage: ObservableValue<null | string> =
    new ObservableValue(null);

  public agentIsTyping: ObservableValue<boolean> = new ObservableValue(false);

  public widgetIsExpanded = new ObservableValue<boolean>(false);
  public showEndChatConfirmation = new ObservableValue<boolean>(false);

  public inWaitingQueue: ObservableValue<string> = new ObservableValue("");

  // The time in milliseconds that the conversation will close due to inactivity. Triggered when an agent sends a reply after a customer message.
  public customerIdleTimeBeforeChatTimeout: ObservableValue<number> =
    new ObservableValue(0);

  // used to set and clear customerIdleTimeBeforeClosingConvo
  public customerIdleTimeoutId = 0;

  // Will be set to true 30 seconds before the conversation closes.
  public countdownTime = new ObservableValue(-1);

  // Time before the conversation ends to show the countdown timer.
  public idleCountdownThreshold = SECOND * 30;

  // Track the widget height to determine if the wrapper should be scrollable
  public widgetHeight = new ObservableValue(0);

  public sendMessageRunner = new AsyncActionRunner<void | null>(null);

  public sendDataMessageRunner = new AsyncActionRunner<void | null>(null);

  public flowDomains = new ObservableValue<Map<string, FlowMessageDomain>>(
    new Map()
  );

  constructor({
    api,
    sessionConversationId,
    sessionUserData,
    sessionSlaT11Timer,
    idleCountdownThreshold,
  }: ConversationMediatorContext) {
    this._api = api;

    this._socketConnection = new ConversationConnectionDomain({
      api,
      user: this.user,
      conversationApiKey: this._conversationApiKey,
      messageTimestamps: this.chatMessages,
      onOpenCallback: () => {
        if (this._retryInterval) {
          clearInterval(this._retryInterval);
          this._retryInterval = 0;
        }

        try {
          this.sendQueuePositionCheck();
        } catch (e) {
          console.log(e);
        }
      },
      onCloseCallback: this.onClose,
    });

    if (idleCountdownThreshold) {
      this.idleCountdownThreshold = idleCountdownThreshold;
    }

    if (sessionConversationId) {
      this._conversationApiKey.setValue(sessionConversationId);

      if (sessionUserData) {
        this.user.setValue(sessionUserData);
      }

      if (sessionSlaT11Timer) {
        this.customerIdleTimeBeforeChatTimeout.setValue(sessionSlaT11Timer);
      }
    }

    this.sendDataMessage = this.sendDataMessage.bind(this);
  }

  public get conversationApiKey() {
    return this._conversationApiKey.getValue();
  }

  public initialize() {
    this._socketConnection.connection.subscribe((message) => {
      this.onMessage(message);
    });

    // Fetch configurations.
    const prev = this.getPreviousConversation();
    // if no previous conversation exists, just get settings for a new one
    if (!prev) {
      this.getWidgetSettings();
    }
  }

  // given an error message, this will set the ui state to show the correct screen
  public setStateFromFailure(message: string) {
    switch (message) {
      case ConversationStates.OPERATING_HOURS_MISS:
        this.conversationState.setValue(
          ConversationStates.OPERATING_HOURS_MISS
        );
        break;
      case ConversationStates.INVALID_CAMPAIGN_EXPIRED:
        this.conversationState.setValue(
          ConversationStates.INVALID_CAMPAIGN_EXPIRED
        );
        break;
      default:
        // unrecognized status message get skipped, stay on same screen
        // just log them instead so we have visibility in console
        console.log(message);
    }
  }

  // TODO: find out how we should handle if this fails. ie. User refreshes and cannot reconnect. Do we want to to clear the session data and make them re-register?
  private joinExistingChat() {
    this._chatReconnected = true;

    const action = () => this._socketConnection.connectToConversation();

    this.initialConnectionRunner.execute(action).catch((err) => {
      console.log(err);
      // handle error in UI
    });
  }

  // pings the campaign to see if it can accept new conversations at this time
  public getWidgetSettings() {
    const action = () => this._api.ping();

    return this.widgetConfigurationRunner
      .execute(action)
      .then((data) => {
        if (!(data as IPingResponse)?.isAvailable) {
          // this should never hit, if ping doesn't fail in the auth step it should return true
          console.log(
            "Ping succeeded but the body said it wasn't available",
            data
          );

          throw new Error(ConversationStates.NO_WIDGET_CONFIG_FOUND);
        }

        setSessionColors(
          JSON.stringify((data as IPingResponse).campaignData.colorProperties)
        );

        this.customerIdleTimeBeforeChatTimeout.setValue(
          (data as IPingResponse).campaignData.slaTimeouts.t11
        );

        this.conversationState.setValue(ConversationStates.IDLE);
      })
      .catch((e) => {
        switch (e.message as string) {
          case ConversationStates.OPERATING_HOURS_MISS:
            return this.fetchConfigurationError.setValue(
              ConversationStates.OPERATING_HOURS_MISS
            );
          case ConversationStates.INVALID_CAMPAIGN_EXPIRED:
            return this.fetchConfigurationError.setValue(
              ConversationStates.INVALID_CAMPAIGN_EXPIRED
            );
          default:
            console.log(e.message);
            return this.fetchConfigurationError.setValue(
              ConversationStates.NO_WIDGET_CONFIG_FOUND
            );
        }
      });
  }

  // if we have previous conversation data, use that and retrieve the colors from storage
  private getPreviousConversation() {
    if (this.conversationApiKey) {
      this.joinExistingChat();
      this.expandWidget();
      return true;
    }
    return false;
  }

  startNewChat(user: IUserData, initialMessage: string) {
    this.chatMessages.setValue([]);
    const action = () => {
      const uiReferenceId = uuid();

      const customerInfo: KnownCustomerMetaData = {
        customer_name: user.fullName,
        customer_email_address: user.emailAddress,
        customer_phone_number: user.phoneNumber,
      };

      const customerCollectedData: CustomerCollectedData = {
        items: Object.entries(customerInfo).map(
          ([key, value]: [string, string]) => ({
            key: key,
            value: value,
          })
        ),
      };

      return this._api
        .createConversation(
          uiReferenceId,
          initialMessage,
          customerCollectedData
        )
        .then((data) => {
          this._conversationApiKey.setValue(data.conversationApiKey);
          this.user.setValue(user);

          setUserSessionData(
            data.conversationApiKey,
            user,
            this.customerIdleTimeBeforeChatTimeout.getValue()
          );

          if (!this.conversationApiKey) {
            throw new Error(
              "No conversation id found when joining existing conversation."
            );
          }
          return this._socketConnection.connectToConversation();
        })
        .catch((e) => {
          this.setStateFromFailure(e.message);
          return;
        });
    };

    return this.initialConnectionRunner.execute(action).catch((err) => {
      console.log(err);
    });
  }

  onAgentTyping(protoChatMessage: IProtoChatMessage) {
    const { payload, customerInformation } = protoChatMessage;
    const { typingNotification } = payload;

    // ignore if this was a customer typing notification that the customer just sent.
    if (typingNotification && customerInformation) {
      return;
    }

    // meaning this chat message came from the customer, not the agent
    const userId = protoChatMessage.userInformation?.userId != null;

    // typing event from agent
    const isTypingEvent = typingNotification != null;
    const isAgentTypingEvent = isTypingEvent && userId;

    // Agent Typing Notification
    if (isAgentTypingEvent && !this.agentIsTyping.getValue()) {
      // Queue position should be reset once agent is typing
      this.inWaitingQueue.setValue("");

      this.agentIsTyping.setValue(true);

      // set to false after 4 seconds.
      this._agentIsTypingTimeoutId = window.setTimeout(() => {
        this.agentIsTyping.setValue(false);
      }, 4000);
    }
  }

  onQueueNotification(protoChatMessage: IProtoChatMessage) {
    const { payload } = protoChatMessage;
    const { queueInformation } = payload;

    // No need to set if there is no value
    if (!queueInformation) {
      return;
    }

    // Check to be sure the queue isn't increasing. Don't want to display the larger value if it is.
    if (
      this._previousWaitingQueue &&
      this._previousWaitingQueue < queueInformation.position
    ) {
      return;
    }

    this._previousWaitingQueue = queueInformation.position;

    if (queueInformation) {
      // Conversation is in Queue at this slot number
      this.inWaitingQueue.setValue(queueInformation.position);
    }
  }

  onConversationAssignment(protoChatMessage: IProtoChatMessage) {
    const message = unpackChatMessage(protoChatMessage);
    const { payload } = message;

    if (payload.unassignConversation) {
      try {
        this.sendQueuePositionCheck();
      } catch (e) {
        console.log(e);
      }
    } else {
      this.stopCheckingQueuePosition();
    }

    // TODO: MOCK API - figure out why two messages are being sent when the agent is assigned
    // dedupe for mock api
    // if (this.chatMessages.getValue().find(({ timeSent }) => timeSent === message.timeSent)) return;

    // this will be appended to the end of the chat messages to be displayed in the ui
    this.chatMessages.append(message);
  }

  onTextMessage(protoChatMessage: IProtoChatMessage) {
    const message = unpackChatMessage(protoChatMessage);

    if (message.senderType === ChatSenderType.OMNI_SENDER_TYPE_SYSTEM) {
      // Just append the message and don't worry about any of the other widget-specific checks below.
      return this.chatMessages.append(message);
    }

    // Watch for a flow message
    if (
      message.messageFormat === MessageFormat.MESSAGE_FORMAT_HTML_FORM &&
      !this.flowDomains.getValue().has(message.id)
    ) {
      const widgetConfiguration = this.widgetConfigurationRunner.getValue();

      const context: FlowMessageDomainContext = {
        messageId: message.id,
        message: message.payload.textMessage?.message || "",
        sendDataMessage: this.sendDataMessage,
        colorProperties: widgetConfiguration
          ? widgetConfiguration.campaignData.colorProperties
          : undefined,
      };

      const domain = new FlowMessageDomain(context);

      this.flowDomains.getValue().set(message.id, domain);

      // Update the conversation messages.
      this.chatMessages.append(message);

      return;
    }

    // dedupe
    if (
      this.chatMessages
        .getValue()
        .find(({ uiReferenceId }) => uiReferenceId === message.uiReferenceId)
    ) {
      return;
    }

    // Clear out other notification before appending message
    window.clearTimeout(this._agentIsTypingTimeoutId);
    this.agentIsTyping.setValue(false);
    this.inWaitingQueue.setValue("");

    this.handleDisplayCloseTimer(message);

    // Update the conversation messages.
    this.chatMessages.append(message);

    // Play audible <ding> when tab is not focused.
    // if (!document.hasFocus() && Platform.OS === "web") {
    // TODO if we want sound to work, we will need to load the audible ding in its own file, and conditionnaly import it if we are on web.
    // const AudibleDing = require("../../assets/sound/ding.wav").default;
    // const audio = new Audio(AudibleDing);
    // audio.play();
    // }
  }

  onDataMessage = (protoChatMessage: IProtoChatMessage) => {
    const { payload } = protoChatMessage;
    const { dataMessage } = payload;
    const message = unpackChatMessage(protoChatMessage);

    if (!dataMessage) return;

    if (
      dataMessage.messageSid &&
      this.flowDomains.getValue().has(dataMessage.messageSid)
    ) {
      const domain = this.flowDomains.getValue().get(dataMessage.messageSid);
      domain?.handleResponse(dataMessage);

      this.chatMessages.append(message);

      return;
    }
  };

  onMessage = (protoChatMessage: IProtoChatMessage) => {
    try {
      const {
        textMessage,
        queueInformation,
        typingNotification,
        assignConversation,
        unassignConversation,
        reassignment,
        dataMessage,
      } = protoChatMessage.payload;

      // Watch for queueInformation payload on message
      if (queueInformation) {
        return this.onQueueNotification(protoChatMessage);
      }

      // handle conversation assignment messages
      if (assignConversation || reassignment || unassignConversation) {
        return this.onConversationAssignment(protoChatMessage);
      }

      // Watch for typingNotification payload on message
      if (typingNotification) {
        return this.onAgentTyping(protoChatMessage);
      }

      // Watch for textMessage payload on message with possible messageFormat
      if (textMessage) {
        return this.onTextMessage(protoChatMessage);
      }

      // Watch for dataMessage payload on message
      if (dataMessage) {
        return this.onDataMessage(protoChatMessage);
      }

      // Watch for omni
    } catch (err) {
      console.log(
        `There was a problem attempting to parse json message : ${err}`
      );
    }
  };

  onError = (e) => {
    console.log("Socket encountered error: ", e.message, "Closing socket");
  };

  onClose = (e) => {
    console.log(`Socket is closed for reason ${e.reason}.`);

    if (
      e.code === StatusCodes.CLOSED_BY_AGENT ||
      e.code === StatusCodes.INACTIVE_CONVERSATION_CLOSED_TIMEOUT ||
      e.code === StatusCodes.CLIENT_CLOSED
    ) {
      this.conversationState.setValue(ConversationStates.FINISHED);

      this.endConversation(true);

      const reasonForSocketClose = {
        [StatusCodes.CLOSED_BY_AGENT]: "Closed By Agent",
        [StatusCodes.INACTIVE_CONVERSATION_CLOSED_TIMEOUT]:
          "Inactive Conversation Closed Timeout",
        [StatusCodes.CLIENT_CLOSED]: "Closed By Client",
      };

      this._lastStatusCode.setValue({
        code: e.code,
        reason: reasonForSocketClose[e.code] ?? "Unknown",
      });

      return;
    }

    if (e.code == StatusCodes.INVALID_CONVERSATION_API_KEY) {
      // Set conversation status as invalid due to something wrong with the campaign.
      this.conversationState.setValue(
        ConversationStates.INVALID_CAMPAIGN_EXPIRED
      );

      this.endConversation();

      this._lastStatusCode.setValue({
        code: e.code,
        reason: "Invalid Conversation",
      });

      return;
    }

    this.conversationState.setValue(ConversationStates.DISCONNECTED);

    // Continue to retry connection every five seconds.
    if (!this._retryInterval) {
      this._retryInterval = window.setInterval(
        () => this._socketConnection.connectToConversation(),
        5000
      );
    }

    this._lastStatusCode.setValue({
      code: e.code,
      reason: e?.reason ?? "Disconnect",
    });
  };

  setWidgetHeight = (height: number) => {
    this.widgetHeight.setValue(height);
  };

  // handles setting a countdown time for the ui to notify the user that the chat is about to expire
  runCountDownTimer() {
    window.clearTimeout(this.customerIdleTimeoutId);

    // if the chat was reconnected, we will have a value for idleCustomerReconnectionElapsedTime
    const customerIdleTimeBeforeClosingConvo =
      this.customerIdleTimeBeforeChatTimeout.getValue() -
      Math.max(this._idleCustomerReconnectionElapsedTime.getValue(), 0);

    // determine if the timeout is less than the threshold and set the countdown time for the ui to display
    if (customerIdleTimeBeforeClosingConvo < this.idleCountdownThreshold) {
      this.countdownTime.setValue(customerIdleTimeBeforeClosingConvo);
    } else {
      // otherwise, set the countdown time for the ui to display after a calculated delay
      this.customerIdleTimeoutId = window.setTimeout(() => {
        this.countdownTime.setValue(this.idleCountdownThreshold);
      }, customerIdleTimeBeforeClosingConvo - this.idleCountdownThreshold);
    }
  }

  handleDisplayCloseTimer(message: IMessagePayload) {
    // check if the current message is from an agent (has userId and is not a manager)
    if (
      message.userId &&
      message.senderType !== ChatSenderType.OMNI_SENDER_TYPE_MANAGER
    ) {
      // since this was an agent message, we determine if we need to start/reset the idle timeout
      // we will if this message comes immediately after a text from the customer or a conversation assignment/reassignment message
      // i.e. we don't care to change the timer if the agent sent another text after their own text
      const lastMessage = this.chatMessages.getLast();
      if (
        // customer text message
        (lastMessage?.userId == undefined &&
          lastMessage?.payload.textMessage) ||
        // conversation assignment/reassigment messages
        lastMessage?.payload.assignConversation ||
        lastMessage?.payload.reassignment
      ) {
        setAgentMessageIdSessionData(message.uiReferenceId);

        // because of a reconnect, check if we already have a t11 initialize message in sessionStorage and if it is this message
        if (
          this._chatReconnected &&
          message.uiReferenceId === getAgentMessageIdSessionData()
        ) {
          // since it is, we update our idleCustomerReconnectionElapsedTime that handleNotifyIdleCustomerNearingTimeout will use
          this._idleCustomerReconnectionElapsedTime.setValue(
            new Date().getTime() - message.timeSent
          );
        }

        this.runCountDownTimer();
      }

      return;
    }

    // wasn't an agent message so we handle the customer message
    // stop showing the countdown timeout notice if it was showing
    if (this.countdownTime.getValue() >= 0) {
      this.countdownTime.setValue(-1);
    }
    // cleanup - timeout, variables, sessionStorage
    window.clearTimeout(this.customerIdleTimeoutId);
    this.customerIdleTimeoutId = 0;
    this._idleCustomerReconnectionElapsedTime.setValue(0);
    clearAgentMessageIdSessionData();
  }

  public async sendMessage(message: string) {
    const action = async () => {
      if (message.length > maxCharCount) {
        throw new Error(
          `Message is longer than the limit of ${maxCharCount} characters.`
        );
      }

      const textMessage: IOmniTextMessage = {
        message,
      };

      const referenceId = uuid();

      await this._socketConnection.sendMessage(referenceId, { textMessage });

      await takeFirst(referenceId, this._socketConnection.connection, 2000);
    };

    return this.sendMessageRunner.execute(action).catch(() => {
      // handle error in UI.
    });
  }

  public async sendDataMessage(message: string, messageId: string) {
    if (!message) {
      throw new Error("No message found to send");
    }

    if (!messageId) {
      throw new Error("No message id was provided");
    }

    const action = async () => {
      const referenceId = uuid();

      const dataMessage: DataMessage = {
        message,
        messageSid: messageId,
      };

      await this._socketConnection.sendMessage(referenceId, {
        dataMessage,
      });
    };

    return this.sendDataMessageRunner.execute(action).catch(() => {
      // handle error in UI.
    });
  }

  public async sendCloseConversationEvent() {
    const referenceId = uuid();
    await this._socketConnection.sendMessage(referenceId, {
      closeConversation: {},
    });

    // let the user see the conversation thread - pass true for dontClearMessages
    this.endConversation(true);
  }

  public sendTypingNotification() {
    if (this._customerIsTyping.getValue()) {
      return;
    }

    this._customerIsTyping.setValue(true);

    this._customerIsTypingTimeout = window.setTimeout(() => {
      this._customerIsTyping.setValue(false);
    }, 4000);

    const referenceId = uuid();
    return this._socketConnection.sendMessage(referenceId, {
      typingNotification: {},
    });
  }

  public sendQueuePositionCheck() {
    const referenceId = uuid();
    this._socketConnection.sendMessage(referenceId, {
      requestQueueInformation: { position: true },
    });

    if (!this._queuePositionCheckInterval) {
      this._queuePositionCheckInterval = window.setInterval(() => {
        try {
          this.sendQueuePositionCheck();
        } catch (err) {
          console.log(err);
        }
      }, 30000);
    }
  }

  stopCheckingQueuePosition() {
    if (this._queuePositionCheckInterval) {
      window.clearInterval(this._queuePositionCheckInterval);
      this._queuePositionCheckInterval = 0;
      this.inWaitingQueue.setValue("");
    }
  }

  endConversation(dontClearMessages: boolean = false) {
    clearUserSessionData();

    this.sendMessageRunner.reset();
    this.sendDataMessageRunner.reset();
    this.user.setValue(null);
    this._conversationApiKey.setValue(null);
    if (!dontClearMessages) this.chatMessages.setValue([]);
  }

  resetAfterChatEnd() {
    this.initialConnectionRunner.reset();
    this.conversationState.setValue(ConversationStates.IDLE);
  }

  expandWidget() {
    this.widgetIsExpanded.setValue(true);
  }

  collapseWidget() {
    this.widgetIsExpanded.setValue(false);
  }

  setShowEndChatConfirmation(value: boolean) {
    this.showEndChatConfirmation.setValue(value);
  }

  handleEndChat() {
    if (!conversationHasEnded(this.conversationState.getValue())) {
      this.setShowEndChatConfirmation(true);
      return;
    }

    this.resetAfterChatEnd();
    this.collapseWidget();
  }

  confirmEndChat() {
    // we don't want to try to reconnect so we set the retryInterval to a truthy value
    this._retryInterval = 1;

    // Stop checking the queue position (need to clear the interval)
    this.stopCheckingQueuePosition();

    this.sendCloseConversationEvent()
      .then(() => {
        this.setShowEndChatConfirmation(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  dispose() {
    this._socketConnection.dispose();

    this._conversationApiKey.dispose();
    this.chatMessages.dispose();
    this.conversationState.dispose();
    this.widgetConfigurationRunner.dispose();
    this.initialConnectionRunner.dispose();
    this.user.dispose();
    this.inWaitingQueue.dispose();
    this.countdownTime.dispose();
    this.customerIdleTimeBeforeChatTimeout.dispose();
    this._idleCustomerReconnectionElapsedTime.dispose();
    this.fetchConfigurationError.dispose();
    this.widgetHeight.dispose();
    this.widgetIsExpanded.dispose();
    this.showEndChatConfirmation.dispose();
    this._customerIsTyping.dispose();
    this.agentIsTyping.dispose();
    this.sendMessageRunner.dispose();
    this.sendDataMessageRunner.dispose();
    this.flowDomains.dispose();
    this.flowDomains.getValue().forEach((domain) => domain.dispose());

    // Clear the timeout and interval ids.
    window.clearTimeout(this._customerIsTypingTimeout);
    window.clearTimeout(this._agentIsTypingTimeoutId);
    window.clearTimeout(this.customerIdleTimeoutId);
    window.clearInterval(this._queuePositionCheckInterval);
    window.clearInterval(this._retryInterval);
  }
}

export default ConversationMediator;
