// Utils/Hooks
import {
  HttpPingResponse,
  ICreateConversationResponse,
  IPingResponse,
} from "@src/mediators/ChatClientApiAdapter/apiTypes";
import {
  createChatConversation,
  ISubscribeToConversationRequestParams,
  subscribeToConversation,
  ping,
} from "@src/mediators/ChatClientApiAdapter/apiEndpoints";
import { unpackPingResponse } from "@src/mediators/ChatClientApiAdapter/unpackUtils";

// Types/Constants
import { IChatClientApi } from "@src/mediators/types/IChatClientApi";
import { CustomerCollectedData } from "@src/mediators/types/customer_collected_data";
import { IOmniMessagePayload } from "@src/mediators/types/IProtoChatMessage";
import { ConversationStates, StatusCodes } from "@src/constants";

interface ICreateConversationRequest {
  message: {
    uiReferenceId: string;
    payload: IOmniMessagePayload;
  };
  customerCollectedData: CustomerCollectedData;
}

// throw special errors so we can catch them in the mediator and show different states
export const throwErrorIfStatusRecognized = (response) => {
  switch (response.status) {
    case StatusCodes.HOUR_MISS_FAILURE: // (Conflict) -- outside hours of operation
      throw new Error(ConversationStates.OPERATING_HOURS_MISS);
    case StatusCodes.INVALID_CHAT: // (Gone) -- group has been stopped/deleted
      throw new Error(ConversationStates.INVALID_CAMPAIGN_EXPIRED);
  }
};

class ChatClientApiAdapter implements IChatClientApi {
  getWebSocketUrl: (
    requestParams: ISubscribeToConversationRequestParams
  ) => string;
  apiKey: string;
  url: string;

  constructor(apiKey: string, url: string) {
    this.apiKey = apiKey;
    this.url = url;
    this.getWebSocketUrl = (
      requestParams: ISubscribeToConversationRequestParams
    ) => subscribeToConversation(this.url, requestParams);
  }

  createConversation(
    uiReferenceId: string,
    initialMessage: string,
    customerCollectedData: CustomerCollectedData
  ): Promise<ICreateConversationResponse> {
    const request: ICreateConversationRequest = {
      message: {
        uiReferenceId: uiReferenceId,
        payload: {
          textMessage: {
            message: initialMessage,
          },
        },
      },
      customerCollectedData: customerCollectedData,
    };

    const options = {
      method: "POST",
      headers: {
        Authorization: this.apiKey,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(request),
    };

    const promise = fetch(
      createChatConversation(this.url, this.apiKey),
      options
    ).then((response) => {
      if (response.ok) {
        return response.json();
      }

      throwErrorIfStatusRecognized(response);
      throw new Error("Failed to create conversation. Please try again.");
    });

    promise.catch((err) => {
      console.log("Failed to create chat conversation", err);
    });

    return promise;
  }

  ping(): Promise<IPingResponse> {
    const promise = fetch(ping(this.url, this.apiKey), {
      method: "GET",
    })
      .then((response) => {
        throwErrorIfStatusRecognized(response);
        if (response.ok) {
          return response
            .json()
            .then((res: HttpPingResponse) => unpackPingResponse(res));
        }
        throw new Error("Failed the ping request, got a bad response");
      })
      .then((data: IPingResponse) => data);

    promise.catch((err) => {
      console.log("Failed to ping", err);
    });
    return promise;
  }
}

export default ChatClientApiAdapter;
