import { filter, take, timeout } from "rxjs/operators";
import { Subject } from "rxjs";

interface UIReferencePort {
  uiReferenceId: string;
}

export function takeFirst(
  uiReferenceId: string,
  connection: Subject<UIReferencePort>,
  timeoutIn = 1000
) {
  return connection
    .asObservable()
    .pipe(
      filter(
        (message: UIReferencePort) => message.uiReferenceId === uiReferenceId
      ),
      take(1),
      timeout(timeoutIn)
    )
    .toPromise();
}
