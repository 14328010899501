import { v4 as uuid } from "uuid";

import {
  ColorProperties,
  HttpCampaignData,
  HttpColorProperties,
  HttpPingResponse,
  HttpWidgetHeader,
  IPingResponse,
  ISlaTimeouts,
} from "@src/mediators/ChatClientApiAdapter/apiTypes";
import {
  ChatSenderType,
  IProtoChatMessage,
} from "@src/mediators/types/IProtoChatMessage";
import { SECOND } from "@src/constants";
import { IMessagePayload } from "@src/mediators/types/IMessagePayload";

export function unpackChatMessages(messages: IProtoChatMessage[]) {
  return messages.map((message) => {
    return unpackChatMessage(message);
  });
}

export function unpackChatMessage(
  protoChatMessage: IProtoChatMessage
): IMessagePayload {
  const id = protoChatMessage.messageSid
    ? protoChatMessage.messageSid
    : protoChatMessage.payload.dataMessage?.messageSid;
  const uiReferenceId =
    protoChatMessage.payload?.assignConversation ||
    protoChatMessage.senderType === ChatSenderType.OMNI_SENDER_TYPE_FLOW
      ? uuid()
      : protoChatMessage.uiReferenceId;

  if (!id) throw new Error("message id not defined from service");
  return {
    uiReferenceId,
    id,
    userId: protoChatMessage.userInformation?.userId,
    sender: deriveChatMessageSender(protoChatMessage),
    // convert unix timestamp to unix milliseconds.
    timeSent: new Date(protoChatMessage.dateCreated).getTime(),
    payload: protoChatMessage.payload,
    // if the sender type doesn't exist, it is a customer message (probably)
    senderType:
      protoChatMessage.senderType ?? ChatSenderType.OMNI_SENDER_TYPE_CUSTOMER,
    messageFormat: protoChatMessage.messageFormat,
  };
}

const deriveChatMessageSender = (
  protoChatMessage: IProtoChatMessage
): string => {
  if (protoChatMessage?.senderType === ChatSenderType.OMNI_SENDER_TYPE_SYSTEM) {
    return "System";
  }

  if (protoChatMessage?.senderType === ChatSenderType.OMNI_SENDER_TYPE_FLOW) {
    return "Flow";
  }

  const customerInformation = protoChatMessage?.customerInformation;

  if (customerInformation) {
    return (
      customerInformation.name ||
      customerInformation.emailAddress ||
      customerInformation.phoneNumber ||
      "N/A"
    );
  }

  return protoChatMessage.userInformation?.name ?? "Agent";
};

export const unpackPingResponse = (res: HttpPingResponse) => {
  const campaignData: HttpCampaignData = res.campaign_data;

  // unpack from snake to camel case.
  return {
    isAvailable: res.is_available,
    campaignData: {
      status: campaignData.status,
      colorProperties: unpackColorProperties(campaignData.color_properties),
      clientEmail: campaignData.email,
      slaTimeouts: unpackSlaTimeouts(campaignData.sla_timeouts),
      header: unpackHeader(campaignData.header),
    },
  } as IPingResponse;
};

const unpackColorProperties = (httpColorProperties: HttpColorProperties) => {
  if (!httpColorProperties) {
    throw new Error("No color properties were given for the config.");
  }
  return {
    mainColor: httpColorProperties.primary_color,
    headingColor: httpColorProperties.header_text_color,
    paragraphColor: httpColorProperties.paragraph_text_color,
  } as ColorProperties;
};

const unpackSlaTimeouts = (httpSlaTimeouts: ISlaTimeouts) => {
  if (!httpSlaTimeouts) {
    throw new Error(
      "No service level agreement timeouts were returned in the config."
    );
  }
  const { t11 } = httpSlaTimeouts;

  return {
    t11: t11 * SECOND,
  } as ISlaTimeouts;
};

const unpackHeader = (httpHeader?: HttpWidgetHeader) => {
  if (!httpHeader || !Object.keys(httpHeader).length) {
    return {
      header: "Let's Connect",
      subheader: "Help us find your profile",
    };
  }

  return httpHeader;
};
