// General
import React from "react";
import { TextInput, Text, StyleSheet, Platform } from "react-native";

// Utils/Hooks
// import lightenDarkenColor from "../utils/lightenDarkenColor";

// Types/Constants
import { fontStyle } from "@src/constants";
import { useColorProperties } from "@src/hooks/useColorProperties";

const styles = StyleSheet.create({
  chatInput: {
    height: 24,
    marginBottom: 8,
    paddingVertical: 0,
    paddingHorizontal: 8,
    borderColor: "#d9d9d9",
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 4,
    fontSize: 11,
    fontFamily: fontStyle,
  },

  chatLabel: {
    marginBottom: 4,
    fontSize: Platform.OS === "android" ? 12 : 10,
    textAlign: "left",
    fontFamily: fontStyle,
    fontWeight: "400",
  },
});

interface IChatInput {
  label: string;
  type: string;
  name: string;
  placeholder?: string;
  required?: boolean;
  inputValue: string;
  setInputVal: (val: string) => void;
  loading: boolean;
}

const ChatInput: React.FC<IChatInput> = ({
  label,
  type,
  name,
  placeholder,
  required = false,
  inputValue,
  setInputVal,
  loading,
}) => {
  const { mainColor, paragraphColor } = useColorProperties();

  // TODO maybe just use a different style sheet so we still keep this ? is it worth it?
  // const loadingBackground = loading
  //   ? `linear-gradient(to right, ${mainColor} 0%, ${lightenDarkenColor(
  //       mainColor,
  //       60
  //     )} 50%, ${mainColor} 100%)`
  //   : "rgba(0,0,0,0.0)"
  const loadingBackground = "rgba(0,0,0,0)";

  const handleInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setInputVal(e.target.value);
  };

  const colorProperties = StyleSheet.create({
    colors: {
      backgroundColor: loadingBackground,
      color: paragraphColor,
    },
  });

  return (
    <React.Fragment>
      <Text
        style={[
          styles.chatLabel,
          // colorProperties.colors,
          // loading ?? styles.loading,
        ]}
      >
        {label}:
      </Text>

      <TextInput
        // do we need the id and name????
        // id={name}
        // name={name}
        data-testid={name}
        style={[
          styles.chatInput,
          // loading ?? styles.loading
        ]}
        value={inputValue}
        onChangeText={(newText) => setInputVal(newText)}
        placeholder={placeholder}
        // TODO: make sure turning this off doesn't break the form
        // required={required}
      />
    </React.Fragment>
  );
};

export default ChatInput;
