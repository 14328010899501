import { IConversationCustomerInformation } from "@src/mediators/types/IProtoConversationCustomerInformation";
import { IUserInformation } from "@src/mediators/types/IProtoUserInformation";

export interface IProtoChatMessage {
  // the id for this given message
  messageSid: string;
  // the message
  payload: IOmniMessagePayload;
  // the time the message got publish and made available for other parties to
  dateCreated: string;
  // a unique id created by the client to ensure it doesn't send the same message twice
  uiReferenceId: string;
  // that sender of this message can be on of customerInformation or userInformation. Not both.
  customerInformation?: IConversationCustomerInformation;
  userInformation?: IUserInformation;
  // the type of the message
  senderType?: ChatSenderType;
  // The format of the message to be displayed
  messageFormat?: MessageFormat;
}

interface OmniTypingNotification {}

export interface OmniCloseConversation {}

export enum MessageFormat {
  MESSAGE_FORMAT_UNSPECIFIED = "MESSAGE_FORMAT_UNSPECIFIED",
  MESSAGE_FORMAT_HTML_FORM = "MESSAGE_FORMAT_HTML_FORM",
}

export interface IOmniTextMessage {
  message: string;
}

interface OmniRequestQueueInformation {
  position: boolean;
}
interface OmniQueuePosition {
  position: string;
}

interface OmniAssignConversation {
  userId: string;
  userName: string;
}

interface OmniUnassignConversation {
  userId: string;
  userName?: string;
  // all: boolean;
}

interface OmniReassignment {
  // currentUserId: string;
  // currentUserName: string;
  // newUserId: string;
  newUserName: string;
}

export interface DataMessage {
  message: string;
  messageSid: string;
}

export interface IOmniMessagePayload {
  textMessage?: IOmniTextMessage;
  typingNotification?: OmniTypingNotification;
  closeConversation?: OmniCloseConversation;
  requestQueueInformation?: OmniRequestQueueInformation;
  queueInformation?: OmniQueuePosition;
  assignConversation?: OmniAssignConversation;
  unassignConversation?: OmniUnassignConversation;
  reassignment?: OmniReassignment;
  dataMessage?: DataMessage;
  // OmniAssignmentNotification reassignment = 102;
  // OmniRequestAttachmentUploadURL request_attachment_upload_url = 103;
  // OmniAttachmentUploadURL attachment_upload_url = 104;
  // OmniAttachment attachment = 105;
  // OmniCloseConversation close_conversation = 106;
  // OmniAssignConversation assign_conversation = 107;
  // OmniUnassignConversation unassign_conversation = 108;
  // OmniFinishWrapUp finish_wrap_up = 109;
}

export enum ChatMessageStatus {
  CHAT_MESSAGE_STATUS_UNKNOWN = 0,
  CHAT_CUSTOMER_MESSAGE_RECEIVED = 16000, //Chat message received from customer
  CHAT_AGENT_MESSAGE_RECEIVED = 16010, //Chat message received from agent
  CHAT_AGENT_MESSAGE_SENDING = 16020, //Chat while agent sending message
  CHAT_AGENT_MESSAGE_SENDING_FAILED = 16030, //Chat failed while agent sending message
  CHAT_AGENT_MESSAGE_SENT = 16040, //Chat agent message queued/sent successfully
}

export enum ChatSenderType {
  OMNI_SENDER_TYPE_AGENT = "OMNI_SENDER_TYPE_AGENT", // Its a chat message reply by an agent
  OMNI_SENDER_TYPE_CUSTOMER = "OMNI_SENDER_TYPE_CUSTOMER", // Received chat message reply from customer
  OMNI_SENDER_TYPE_SYSTEM = "OMNI_SENDER_TYPE_SYSTEM", // Chat message from system
  OMNI_SENDER_TYPE_MANAGER = "OMNI_SENDER_TYPE_MANAGER", // Chat message from manager
  OMNI_SENDER_TYPE_FLOW = "OMNI_SENDER_TYPE_FLOW", // Chat message from manager
}
