// General
import React from "react";

// Components/Shared
import ChatWrapper, { Chat } from "@src/components/ChatWrapper";

// Utils/Hooks
import { createUseStyles, jss } from "react-jss";
import increaseSpecificity from "@src/utils/increaseSpecificity";
// mock
//import { randomEmail } from "./mediators/ChatClientApiAdapter/mockDataGenerator";

// Types/Constants
import ChatConfigurationProvider, {
  IOmniChatClient,
} from "@src/providers/ChatConfigurationProvider";
import ConversationMediatorProvider from "@src/providers/ConversationMediatorProvider";
import { fontStyle } from "@src/constants";

// mock
//import MockChatClientApi from "./mediators/ChatClientApiAdapter/ChatClientMockApiAdapter";

const useStyles = createUseStyles({
  "@global": {
    "#tcn-chat-root": {
      fontFamily: fontStyle,
      position: "fixed",
      right: 0,
      bottom: 0,
      zIndex: 999,

      "& *": {
        boxSizing: "border-box",
        // border: 0,
      },
    },
  },
});

interface OmniChatClientProps {
  config: IOmniChatClient;
  zIndex?: number;
}

const OmniChatClient = ({ config, zIndex }: OmniChatClientProps) => {
  // const mockApi = new MockChatClientApi({
  //   agentName: randomEmail(),
  //   url: config?.url ?? "chat.dev.tcn.com",
  // });

  useStyles();

  // Adds a layer of specificity to styles so they don't get overwritten by appending the widget id to all of the styles.
  jss.setup().use(increaseSpecificity(1, "#tcn-chat-root "));

  return (
    <ChatConfigurationProvider config={config}>
      <ConversationMediatorProvider>
        <ChatWrapper>
          <Chat zIndex={zIndex} />
        </ChatWrapper>
      </ConversationMediatorProvider>
    </ChatConfigurationProvider>
  );
};

export default OmniChatClient;
