// General
import React, { useEffect, useState } from "react";
import { View, Text, TextInput, Pressable, StyleSheet } from "react-native";

// Components/Shared
import DisplayCount from "@src/components/DisplayCount";
import ErrorMessageText from "@src/components/ErrorMessageText";
import { SendSvg } from "@src/assets/send";

// Utils/Hooks
import { useConversationMediator } from "@src/providers/ConversationMediatorProvider";
import { usePendingMessageStatus } from "@src/hooks/usePendingMessageStatus";
import { useFailedMessageStatus } from "@src/hooks/useFailedMessageStatus";
import { useColorProperties } from "@src/hooks/useColorProperties";

// Types/Constants
import { CountdownTimerMediator } from "@src/mediators/CountdownTimer/CountdownTimerMediator";
import { fontStyle } from "@src/constants";
import { useAsyncValue } from "@src/hex/use_async_value";

const styles = StyleSheet.create({
  chatReply: {
    position: "relative",
    flexShrink: 0,
    paddingLeft: 8,
    paddingRight: 8,
  },
  informationSlot: {
    minHeight: 12,
    backgroundColor: "transparent",
  },
  chatReplyInput: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 4,
    paddingRight: 4,
    backgroundColor: "transparent",
    borderRadius: 4,
    borderWidth: 0.5,
    borderStyle: "solid",
    borderColor: "#669eb4",
    fontSize: 12,
    width: "100%",
    height: 52,
    shadowRadius: 0,
    margin: 0,
    textAlign: "left",
    color: "#000000",
    fontFamily: fontStyle,
  },

  chatSendButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 8,
    paddingRight: 8,
    height: 26,
    borderRadius: 4,
    fontSize: 10,
    fontWeight: "400",
    marginTop: 8,
    marginLeft: "auto",
  },

  sendImage: {
    marginLeft: 4,
    width: 14.5,
    height: 13,
  },

  notification: {
    fontSize: 10,
    color: "#AAAAAA",
    textAlign: "center",
    marginBottom: 8,
  },

  agentTyping: {
    marginLeft: 12,
    marginBottom: 5,
    textAlign: "left",
  },

  idleCountdown: {
    fontWeight: "700",
    color: "#ff6565",
  },
  disabledCursor: {
    //@ts-ignore
    cursor: "not-allowed",
  },
  fillerElement: {
    height: 98,
  },
});

const ChatReply: React.FC = () => {
  const mediator = useConversationMediator();

  const chatMessages = useAsyncValue(mediator.chatMessages);
  const agentIsTyping = useAsyncValue(mediator.agentIsTyping);
  const inWaitingQueueSlot = useAsyncValue(mediator.inWaitingQueue);
  const countdownTime = useAsyncValue(mediator.countdownTime);

  const latestMessage = chatMessages[chatMessages.length - 1];
  const pendingMessage = usePendingMessageStatus();
  const failedMessage = useFailedMessageStatus();

  // had to do it like this because if we just check inWaitingQueueSlot it gets mad because "text has to be wrapped in a Text component"
  const isInWaitingQueueSlot = Boolean(inWaitingQueueSlot);

  const [inputVal, setInputVal] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const { mainColor, headingColor, paragraphColor } = useColorProperties();
  const failedInitialMessage = useAsyncValue(mediator.failedInitialMessage);

  useEffect(() => {
    setErrorMessage("Sorry there was an error trying to send");
  }, [failedMessage]);

  useEffect(() => {
    if (failedInitialMessage) {
      setInputVal(failedInitialMessage);
    }
  }, [failedInitialMessage]);

  const handleKeyPress = () => {
    if (!inputVal.trim() || pendingMessage) return;
    mediator.sendTypingNotification();
  };

  const handleSubmit = async () => {
    try {
      if (inputVal.trim() && !pendingMessage) {
        const cleanedText = inputVal.replace(/<[^>]+>/g, "");
        await mediator.sendMessage(cleanedText);
        setInputVal("");
      }
    } catch {
      // TODO: err
    }
  };

  const textColorProperties = StyleSheet.create({
    colors: { borderColor: mainColor, color: paragraphColor },
  });

  var buttonColorProperties = StyleSheet.create({
    button: { color: headingColor, backgroundColor: mainColor },
    send: { color: headingColor, paddingRight: 5, fontSize: 10 },
  });

  const disabled = !inputVal || pendingMessage;

  // Don't show the reply input if the last message has a messageFormat(FlowMessage) && the messageId matches the last FlowDomain
  if (
    latestMessage?.messageFormat &&
    !mediator.flowDomains
      .getValue()
      .get(latestMessage.id)
      ?.dataMessageResponse.getValue()
  ) {
    return null;
  }

  return (
    <View style={styles.chatReply}>
      <View style={styles.informationSlot}>
        {isInWaitingQueueSlot && (
          <>
            <Text style={styles.notification}>
              Waiting for next available agent...
            </Text>
            <Text style={styles.notification}>
              Queue Position: {inWaitingQueueSlot}
            </Text>
          </>
        )}

        {agentIsTyping && (
          <Text style={[styles.notification, styles.agentTyping]}>
            Agent is typing...
          </Text>
        )}

        {countdownTime >= 0 && <CountdownTimer countdownTime={countdownTime} />}

        {failedMessage && <ErrorMessageText errorMessage={errorMessage} />}
      </View>

      <TextInput
        style={[styles.chatReplyInput, textColorProperties.colors]}
        placeholder="Write a message..."
        multiline
        textAlignVertical="top"
        autoFocus
        value={inputVal}
        onKeyPress={handleKeyPress}
        onChangeText={(newText) => setInputVal(newText)}
      />
      <DisplayCount length={inputVal.length} />
      <Pressable
        style={[
          styles.chatSendButton,
          buttonColorProperties.button,
          disabled && styles.disabledCursor,
        ]}
        onPress={handleSubmit}
        disabled={disabled}
      >
        <Text style={buttonColorProperties.send}>Send</Text>
        <SendSvg fillColor={headingColor} />
      </Pressable>
    </View>
  );
};

const CountdownTimer = ({ countdownTime }: { countdownTime: number }) => {
  const [countdownTimer] = useState<CountdownTimerMediator>(
    () => new CountdownTimerMediator(countdownTime)
  );

  const remainingIdleTime = useAsyncValue(countdownTimer.countdownTime);

  useEffect(() => {
    countdownTimer.start();
    return () => {
      countdownTimer.dispose();
    };
  }, [countdownTimer]);

  return (
    <Text style={[styles.notification, styles.idleCountdown]}>
      Your chat session will expire in{" "}
      {countdownTimer.formatCountdownTime(remainingIdleTime)}
    </Text>
  );
};

export default ChatReply;
