import React from "react";
import Svg, { Path } from "react-native-svg";

export const SendSvg = ({ fillColor }) => {
  return (
    <Svg width="15" height="15" viewBox="0 0 15 15" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.53611 2.17618C1.6728 2.03313 1.88459 1.99131 2.06539 2.07167L13.3154 7.07169C13.4847 7.14688 13.5938 7.31475 13.5938 7.5C13.5938 7.68525 13.4847 7.85313 13.3154 7.92838L2.06539 12.9284C1.88459 13.0088 1.6728 12.9669 1.53611 12.8239C1.39942 12.6808 1.36726 12.4674 1.45575 12.2904L3.61656 7.96875H7.5C7.75887 7.96875 7.96875 7.75888 7.96875 7.5C7.96875 7.24113 7.75887 7.03125 7.5 7.03125H3.61655L1.45575 2.70965C1.36726 2.53268 1.39942 2.31922 1.53611 2.17618Z"
        fill={fillColor}
      />
    </Svg>
  );
};
