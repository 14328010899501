// General
import React from "react";
import { View, StyleSheet, Platform, ViewStyle } from "react-native";

// Components/Shared
import ChatForm from "@src/components/ChatForm";
import ChatConversation from "@src/components/ChatConversation";
import ChatUnavailable from "@src/components/ChatUnavailable";
import ChatFooter from "@src/components/ChatFooter";
import ChatHeader from "@src/components/ChatHeader";

// Utils/Hooks
import { useConversationMediator } from "@src/providers/ConversationMediatorProvider";
import { useConversationStatus } from "@src/hooks/useConversationStatus";
import { useWidgetHeight } from "@src/hooks/useWidgetHeight";
import { useUser } from "@src/hooks/useUser";
import { conversationHasEnded } from "@src/utils";

// Types/Constants
import { IPingResponse } from "@src/mediators/ChatClientApiAdapter/apiTypes";
import { IUserData } from "@src/mediators/types/IUserData";
import {
  ConversationStates,
  widgetSpacing,
  chatBoxStyles,
} from "@src/constants";
import { useInitialConnectionState } from "@src/hooks/useInitialConnectionState";
import { useAsyncValue } from "@src/hex/use_async_value";

const shadowStyle: ViewStyle =
  Platform.OS === "android"
    ? {
        shadowColor: "black",
        elevation: 21,
      }
    : {
        shadowColor: "black",
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.2,
        shadowRadius: 10.5,
      };

const styles = StyleSheet.create({
  chatBoxStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: chatBoxStyles.width,
    maxWidth: "100%",
    maxHeight: chatBoxStyles.height,
    marginTop: "auto",
    marginBottom: widgetSpacing.fromBottom,
    marginLeft: widgetSpacing.fromLeft,
    marginRight: widgetSpacing.fromRight,
    flexShrink: 0,
    backgroundColor: "#ffffff",
    borderRadius: chatBoxStyles.borderRadius,
    ...shadowStyle,
  },
  mainSectionStyle: {
    flexShrink: 0,
    display: "flex",
    justifyContent: "space-around",
  },
});

const ChatBox = () => {
  const user: IUserData | null = useUser();
  const heading = `Hello ${user?.fullName || user?.emailAddress || ""}`;
  const subHeading = "How can we help you?";

  const conversationStatus = useConversationStatus();
  const conversationEndedOrUnavailable =
    conversationHasEnded(conversationStatus);
  const conversationEnded = conversationStatus === ConversationStates.FINISHED;

  const connected = useInitialConnectionState().isSuccess;

  const displayForm = !connected && !conversationEndedOrUnavailable;

  const mediator = useConversationMediator();
  const campaignData = (
    useAsyncValue(mediator.widgetConfigurationRunner) as IPingResponse
  )?.campaignData;

  const formHeading = campaignData?.header.header ?? "Let's Connect";
  const formSubHeading =
    campaignData?.header.subheader ?? "Help us find your profile";

  const unavailableHeading = "Chat Is Not Available";
  const unavailableSubHeading = "Please check back later";

  const getHeading = () => {
    if (displayForm) return formHeading;
    return connected || conversationEnded ? heading : unavailableHeading;
  };

  const getSubHeading = () => {
    if (displayForm) return formSubHeading;
    return connected || conversationEnded ? subHeading : unavailableSubHeading;
  };

  const { onWidgetLayout } = useWidgetHeight();

  return (
    <View
      nativeID="tcn-chat-widget"
      style={styles.chatBoxStyle}
      onLayout={function (e) {
        onWidgetLayout(e);
      }}
    >
      <ChatHeader
        heading={getHeading()}
        subHeading={getSubHeading()}
        isForm={displayForm}
      />

      <View nativeID="tcn-chat-widget-inner" style={styles.mainSectionStyle}>
        {displayForm ? (
          <ChatForm />
        ) : connected || conversationEnded ? (
          <ChatConversation />
        ) : (
          <ChatUnavailable
            bannerHeading={
              conversationStatus === ConversationStates.OPERATING_HOURS_MISS
                ? "No availability at this hour"
                : conversationStatus === ConversationStates.NOBODY_HOME
                ? "No agents available to chat"
                : undefined
            }
          />
        )}
      </View>

      <ChatFooter />
    </View>
  );
};

export default ChatBox;
