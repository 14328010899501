import React, { useEffect, useRef, useState } from "react";

//@ts-ignore
import { WebView } from "react-native-webview";

import {
  messageResponseScript,
  scriptString,
} from "@src/components/flows/flow_message/script";
import { ResponseValues } from "@src/components/flows/flow_message/utils";
import { useAsyncValue } from "@src/hex/use_async_value";
import { FlowMessageDomain } from "@src/mediators/ConversationFlow/flow_message_domain";
import { DataMessage } from "@src/mediators/types/IProtoChatMessage";

interface WebViewNativeProps {
  messageId: string;
  flowDomain: FlowMessageDomain;
}

export const WebViewNative = ({
  flowDomain,
  messageId,
}: WebViewNativeProps) => {
  const html = useAsyncValue(flowDomain.presenter.updatedHtml);
  const response = useAsyncValue(flowDomain.dataMessageResponse);
  const [viewportHeight, setViewportHeight] = useState<number>(0);
  const webViewRef = useRef<WebView>(null);

  const buildScript = (response: DataMessage): string => {
    const responseObj: ResponseValues = JSON.parse(response.message);
    return messageResponseScript(responseObj);
  };

  useEffect(() => {
    if (response && webViewRef.current) {
      const script = buildScript(response);
      webViewRef.current.injectJavaScript(script);
    }
  }, [response]);

  const handleWebViewMessage = async (event) => {
    if (event.nativeEvent.data) {
      const parsedData = JSON.parse(event.nativeEvent.data);

      if (parsedData.viewportHeight) {
        setViewportHeight(parsedData.viewportHeight);
        return;
      }

      try {
        await flowDomain.sendResponse(event.nativeEvent.data);
      } catch (e) {
        console.error(e);
      }
    } else {
      return;
    }
  };

  return (
    <WebView
      source={{ html: html }}
      injectedJavaScript={scriptString()}
      onMessage={handleWebViewMessage}
      ref={webViewRef}
      scrollEnabled={false}
      onLoad={() => {
        if (response && webViewRef.current) {
          const script = buildScript(response);
          webViewRef.current.injectJavaScript(script);
        }
      }}
      style={{
        display: "flex",
        flexDirection: "column",
        height: viewportHeight,
      }}
      onError={(event) => {
        alert(event.nativeEvent.description);
      }}
      id={messageId}
      startInLoadingState={true}
    />
  );
};
