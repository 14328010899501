import { IConversationCustomerInformation } from "@src/mediators/types/IProtoConversationCustomerInformation";
import { IUserData } from "@src/mediators/types/IUserData";

export const packUserInformation = ({
  fullName,
  emailAddress,
  phoneNumber,
}: IUserData) => {
  const userInformation: IConversationCustomerInformation = {};

  if (fullName) {
    userInformation.name = fullName;
  }

  if (emailAddress) {
    userInformation.emailAddress = emailAddress;
  }

  if (phoneNumber) {
    userInformation.phoneNumber = phoneNumber;
  }

  return userInformation;
};
