// General
import React, { useEffect, useState } from "react";
import { Text, Pressable, StyleSheet, ScrollView } from "react-native";

// Components/Shared
import ChatInput from "@src/components/ChatInput";
import ErrorMessageText from "@src/components/ErrorMessageText";

// Utils/Hooks
import { useConversationMediator } from "@src/providers/ConversationMediatorProvider";
import { useInitialConnectionState } from "@src/hooks/useInitialConnectionState";
import { useColorProperties } from "@src/hooks/useColorProperties";
import { chatBoxStyles, fontStyle } from "@src/constants";

// Types/Constants

const styles = StyleSheet.create({
  chatForm: {
    width: "100%",
    maxHeight:
      chatBoxStyles.height -
      chatBoxStyles.header.height -
      chatBoxStyles.footer.height,
    paddingTop: 18,
    paddingBottom: 0,
    paddingHorizontal: 18,
  },

  chatFormSubmit: {
    borderRadius: 4,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 4,
    paddingHorizontal: 8,
    height: 26,
    width: "100%",
    fontSize: 14,
    borderWidth: 0,
    shadowRadius: 0,
    marginTop: 10,
    marginBottom: 4,
    fontFamily: fontStyle,
    // TODO: make sure turning this off doesn't break anything
    // "&:disabled": {
    //   backgroundColor: "#dfd7cd !important",
    // },
  },

  // TODO: get the loading animation working - different MR
  // loading: {
  //   animation: "$shimmer 2s infinite",
  //   backgroundSize: "1000px 100%",
  //   position: "relative",
  //   color: "transparent !important",
  //   border: "none",
  //   borderRadius: 4,
  //   display: "block",
  // },

  // "@keyframes shimmer": {
  //   "0%": {
  //     backgroundPosition: "-500px 0",
  //   },
  //   "100%": {
  //     backgroundPosition: "500px 0",
  //   },
  // },
});

const ChatForm = React.forwardRef(() => {
  const mediator = useConversationMediator();

  const { mainColor, headingColor, paragraphColor: _ } = useColorProperties();
  const { isPending, error, isError } = useInitialConnectionState();

  // TODO maybe just use a different style sheet so we still keep this ? is it worth it?
  // const loadingBackground = isPending
  //   ? `linear-gradient(to right, ${mainColor} 0%, ${lightenDarkenColor(
  //       mainColor,
  //       60
  //     )} 50%, ${mainColor} 100%)`
  //   : mainColor;
  const loadingBackground = mainColor;

  const [initialMessage, setInitialMessage] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [failedMessage, setFailedMessage] = useState("");

  useEffect(() => {
    if (isError) {
      setFailedMessage(`Couldn't create conversation: ${error?.message}`);
    }
  }, [isError, error]);

  const startNewChat = async () => {
    try {
      if (!initialMessage.trim()) throw "An initial message is needed";
      if (!firstName.trim() && !lastName.trim() && !emailAddress.trim())
        throw "At least one customer identifier is needed to submit.";

      const user = {
        fullName: `${firstName} ${lastName}`,
        emailAddress,
        phoneNumber,
      };

      await mediator.startNewChat(user, initialMessage);
    } catch (err) {
      // TODO:
    }
  };

  const buttonIsDisabled = isPending || !initialMessage.trim();

  const colorProperties = StyleSheet.create({
    pressableColors: {
      color: headingColor,
      backgroundColor: loadingBackground,
    },
    headerColor: {
      color: headingColor,
      fontSize: 14,
      lineHeight: 18,
    },
  });

  return (
    <ScrollView nestedScrollEnabled style={styles.chatForm}>
      <ChatInput
        loading={isPending}
        required
        label="First Name"
        name="firstName"
        type="text"
        inputValue={firstName}
        setInputVal={(val) => setFirstName(val)}
      />
      <ChatInput
        loading={isPending}
        required
        label="Last Name"
        name="lastName"
        type="text"
        inputValue={lastName}
        setInputVal={(val) => setLastName(val)}
      />
      <ChatInput
        loading={isPending}
        required
        label="Email"
        name="email"
        type="email"
        inputValue={emailAddress}
        setInputVal={(val) => setEmailAddress(val)}
      />
      <ChatInput
        loading={isPending}
        label="Phone Number (optional)"
        name="phone"
        type="tel"
        inputValue={phoneNumber}
        setInputVal={(val) => setPhoneNumber(val)}
      />
      <ChatInput
        loading={isPending}
        required
        label="Question"
        name="message"
        type="text"
        inputValue={initialMessage}
        setInputVal={(val) => setInitialMessage(val)}
      />

      {isError && <ErrorMessageText errorMessage={failedMessage} />}

      <Pressable
        disabled={buttonIsDisabled}
        style={[
          styles.chatFormSubmit,
          colorProperties.pressableColors,
          buttonIsDisabled && { backgroundColor: "#dfd7cd" },
          // TODO: turn this back on when loading animation is working
          // isPending && styles.loading,
        ]}
        onPress={startNewChat}
      >
        <Text style={colorProperties.headerColor}>Connect me</Text>
      </Pressable>
    </ScrollView>
  );
});

export default ChatForm;
