import React from "react";
import Svg, { Path } from "react-native-svg";

export const ChatSvg = () => {
  return (
    <Svg width="22" height="22" viewBox="0 0 22 22" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.67308 10.875C1.67308 5.79292 5.79292 1.67308 10.875 1.67308C15.9571 1.67308 20.0769 5.79292 20.0769 10.875C20.0769 15.957 15.9571 20.0769 10.875 20.0769H2.38325C2.44501 19.9775 2.5026 19.8825 2.55597 19.7915C2.80705 19.3632 2.9995 18.966 3.09646 18.5476C3.1661 18.2472 3.18345 18.1234 3.1991 17.8155C3.22206 17.3635 3.10756 16.8264 2.93951 16.2127C2.76669 15.5816 2.50434 14.7611 2.16588 13.7026L2.15928 13.682C1.87241 12.7848 1.67308 11.8182 1.67308 10.875ZM0 20.9135C0 20.7377 0.0542188 20.5746 0.14684 20.44C0.584785 19.783 0.89368 19.3188 1.11262 18.9454C1.33172 18.5716 1.42597 18.3452 1.4666 18.1698C1.49504 18.0471 1.50503 17.9957 1.51115 17.9521C1.51726 17.9085 1.52179 17.8564 1.52818 17.7306C1.53614 17.5739 1.49408 17.2689 1.32585 16.6546C1.16349 16.0616 0.912095 15.2749 0.56569 14.1916C0.244682 13.1876 0 12.043 0 10.875C0 4.8689 4.8689 0 10.875 0C16.8811 0 21.75 4.8689 21.75 10.875C21.75 16.8811 16.8811 21.75 10.875 21.75H0.836538C0.374535 21.75 0 21.3755 0 20.9135ZM6.41346 11.9904C7.02947 11.9904 7.52885 11.491 7.52885 10.875C7.52885 10.259 7.02947 9.75961 6.41346 9.75961C5.79746 9.75961 5.29808 10.259 5.29808 10.875C5.29808 11.491 5.79746 11.9904 6.41346 11.9904ZM11.9904 10.875C11.9904 11.491 11.491 11.9904 10.875 11.9904C10.259 11.9904 9.75961 11.491 9.75961 10.875C9.75961 10.259 10.259 9.75961 10.875 9.75961C11.491 9.75961 11.9904 10.259 11.9904 10.875ZM15.3365 11.9904C15.9526 11.9904 16.4519 11.491 16.4519 10.875C16.4519 10.259 15.9526 9.75961 15.3365 9.75961C14.7205 9.75961 14.2212 10.259 14.2212 10.875C14.2212 11.491 14.7205 11.9904 15.3365 11.9904Z"
        fill="white"
      />
    </Svg>
  );
};
