import { SECOND } from "@src/constants";
import { ObservableValue } from "@src/hex/observable_value";

export class CountdownTimerMediator {
  countdownTime: ObservableValue<number>;

  intervalId = 0;

  constructor(startingCountdownTime: number) {
    this.countdownTime = new ObservableValue(startingCountdownTime);
  }

  start() {
    if (this.countdownTime.getValue() > 0) {
      this.intervalId = window.setInterval(() => {
        const countdownTime = this.countdownTime.getValue();

        if (countdownTime > 0) {
          this.countdownTime.setValue(countdownTime - SECOND);
        }
      }, SECOND);
    }
  }

  formatCountdownTime(ms: number) {
    if (!ms || ms < 0) return "00:00";
    // format ms to mm:ss

    const minutes = Math.floor(ms / (SECOND * 60));
    const seconds = Math.floor((ms % (SECOND * 60)) / SECOND);

    const formattedSeconds = String(seconds).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  }

  dispose() {
    clearInterval(this.intervalId);
    this.countdownTime.dispose();
  }
}
