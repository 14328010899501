export function createDom(html: string): Document {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  return doc;
}

export function stringifyDocument(doc: Document): string {
  const serializer = new XMLSerializer();
  return serializer.serializeToString(doc);
}

export interface ResponseValues {
  [key: string]: string | boolean | undefined;
}

export function getPromptResponseValue(obj: ResponseValues) {
  for (const value of Object.values(obj)) {
    if (value && typeof value === "string") {
      return value;
    }
  }
}

export const resizeIframeToHtmlHeight = (id: string) => {
  const iframe = document.getElementById(id);
  const htmlIFrame = iframe as HTMLIFrameElement;
  if (iframe && htmlIFrame.contentWindow) {
    const body = htmlIFrame.contentWindow.document.body;
    const html = htmlIFrame.contentWindow.document.documentElement;
    const height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.offsetHeight
    );
    iframe.style.height = height + "px";
  }
};

export type StyleSheet = {
  [selector: string]: {
    [property: string]: string;
  };
};

export function convertStyleSheetToString(styleSheet: StyleSheet): string {
  let cssString = "";

  for (const [selector, properties] of Object.entries(styleSheet)) {
    cssString += `${selector} {\n`;
    for (const [property, value] of Object.entries(properties)) {
      cssString += `  ${property}: ${value};\n`;
    }
    cssString += `}\n`;
  }

  return cssString;
}
