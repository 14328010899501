import { Subject } from "rxjs";
import { ISubscribeToConversationRequestParams } from "@src/mediators/ChatClientApiAdapter/apiEndpoints";
import {
  IOmniMessagePayload,
  IProtoChatMessage,
} from "@src/mediators/types/IProtoChatMessage";
import { ISendMessageRequest } from "@src/mediators/ConversationMediator/ConversationMediator";
import { packUserInformation } from "@src/mediators/ChatClientApiAdapter/packUtils";
import { ReadonlyObservableValue } from "@src/hex/observable_value";
import { ObservableArray } from "@src/hex/observable_array";
import { IUserData } from "@src/mediators/types/IUserData";
import { StatusCodes } from "@src/constants";

interface ConversationConnectionApiPort {
  // this is to determine which URL to use between the mock.
  getWebSocketUrl: (
    queryParams: ISubscribeToConversationRequestParams
  ) => string;
}

export interface ConversationConnectionDomainPort {
  connection: Subject<IProtoChatMessage>;
  connectToConversation(): Promise<void>;
  sendMessage(
    uiReferenceId: string,
    message: IOmniMessagePayload
  ): Promise<void>;
  dispose(): void;
}

interface ConversationConnectionDomainContext {
  api: ConversationConnectionApiPort;
  user: ReadonlyObservableValue<IUserData | null>;
  conversationApiKey: ReadonlyObservableValue<string | null>;
  messageTimestamps: ObservableArray<{ timeSent: number }>;
  onOpenCallback: () => void;
  onCloseCallback: (e: { code: number; reason: string }) => void;
}

export class ConversationConnectionDomain
  implements ConversationConnectionDomainPort
{
  public connection: Subject<IProtoChatMessage>;

  private _retryResetTimer: number | null = null;

  private socket: WebSocket | null = null;

  private _context: ConversationConnectionDomainContext;

  constructor(context: ConversationConnectionDomainContext) {
    this._context = context;

    this.connection = new Subject<IProtoChatMessage>();
  }

  public connectToConversation() {
    // Create a web socket connection.
    return new Promise<void>((res) => {
      const conversationApiKey = this._context.conversationApiKey.getValue();
      if (!conversationApiKey) {
        throw new Error(
          "There was no conversation id when connecting to conversation"
        );
      }

      // Build the query params for the web socket GET request.
      const user = this._context.user.getValue();
      const lastMessageTimestamp =
        this._context.messageTimestamps?.getLast()?.timeSent;

      const subscribeToConversationRequest: ISubscribeToConversationRequestParams =
        {
          apiKey: conversationApiKey,
          // This is really the last message timestamp but we are calling it first message timestamp to match the backend.
          firstMessageTimestamp: lastMessageTimestamp
            ? Math.floor(lastMessageTimestamp / 1000)
            : 1,
          fullName: user?.fullName,
          phoneNumber: user?.phoneNumber,
          emailAddress: user?.emailAddress,
        };

      // WSS API
      const ws = new WebSocket(
        this._context.api.getWebSocketUrl(subscribeToConversationRequest)
      );

      ws.onopen = () => {
        // save the web socket into the class instance.
        this.socket = ws;

        res();

        this._context.onOpenCallback();
      };

      ws.onmessage = this.onMessage;
      ws.onerror = this.onError;
      ws.onclose = this.onClose;
    });
  }

  public sendMessage = async (
    uiReferenceId: string,
    payload: IOmniMessagePayload
  ) => {
    if (!this.socket) {
      throw new Error(
        "Failed to send message because a connection was not established."
      );
    }

    const user = this._context.user.getValue();

    if (!user) {
      throw new Error("Failed to send message because user is not logged in.");
    }

    if (!user.fullName && !user.emailAddress) {
      throw new Error("No user identifier was found when sending message.");
    }
    if (!this._context.conversationApiKey) {
      throw new Error("No conversation id was found when sending message.");
    }

    // If the socket connection is closed we should reconnect.
    if (
      this.socket.readyState === WebSocket.CLOSING ||
      this.socket.readyState === WebSocket.CLOSED
    ) {
      try {
        await this.connectToConversation();
      } catch (err) {
        const errMsg = `Failed to reconnect when sending message: ${err}`;
        console.log(errMsg);

        throw new Error(errMsg);
      }
    }

    // Format the message for sending.
    const messagePayload: ISendMessageRequest = {
      payload,
      uiReferenceId,
      customerInformation: packUserInformation(user),
    };

    // Send message
    return this.socket.send(JSON.stringify(messagePayload));
  };

  private onMessage = (e) => {
    this._retryResetTimer && window.clearTimeout(this._retryResetTimer);

    try {
      const protoChatMessage: IProtoChatMessage = JSON.parse(e.data);
      this.connection.next(protoChatMessage);
    } catch (err) {
      console.log(
        `There was a problem attempting to parse json message : ${err}`
      );
    }
  };

  private onError = (e) => {
    console.log("Socket encountered error: ", e.message, "Closing socket");
  };

  private onClose = (e) => {
    this._context.onCloseCallback(e);
  };

  private closeSocket(code: StatusCodes, reason: string) {
    this.socket && this.socket.close(code, reason);
  }

  public dispose() {
    this.closeSocket(StatusCodes.CLIENT_CLOSED, "Shut Down");
    this.connection.complete();
  }
}
