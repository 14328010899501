import {
  FlowMessagePresenter,
  FlowMessagePresenterPort,
} from "@src/components/flows/flow_message/presenter";
import { AsyncActionRunner } from "@src/hex/async_action_runner";
import { ObservableValue } from "@src/hex/observable_value";
import { ColorProperties } from "@src/mediators/ChatClientApiAdapter/apiTypes";
import { DataMessage } from "@src/mediators/types/IProtoChatMessage";

export interface FlowMessageDomainPort {
  sendResponseRunner: AsyncActionRunner<void | null>;
  dataMessageResponse: ObservableValue<DataMessage | null>;
  sendResponse(response: string): Promise<void | null>;
  retrySendResponse(): Promise<void>;
  handleResponse(response: DataMessage): void;
  presenter: FlowMessagePresenterPort;
  dispose(): void;
}

export interface FlowMessageDomainContext {
  messageId: string;
  message: string;
  sendDataMessage(response: string, messageId: string): Promise<void | null>;
  colorProperties: ColorProperties | undefined;
}

export class FlowMessageDomain implements FlowMessageDomainPort {
  private _messageId: string;
  private _sendResponseRunner: AsyncActionRunner<void | null>;
  private _dataMessageResponse: ObservableValue<DataMessage | null>;

  public sendDataMessage: (
    response: string,
    messageId: string
  ) => Promise<void | null>;
  public presenter: FlowMessagePresenterPort;
  public response = new ObservableValue<string>("");

  constructor({
    messageId,
    message,
    sendDataMessage,
    colorProperties,
  }: FlowMessageDomainContext) {
    this._messageId = messageId;
    this.sendDataMessage = sendDataMessage;
    this._dataMessageResponse = new ObservableValue<DataMessage | null>(null);
    this._sendResponseRunner = new AsyncActionRunner<void | null>(null);
    this.presenter = new FlowMessagePresenter(message, colorProperties);
  }

  public get sendResponseRunner() {
    return this._sendResponseRunner;
  }

  public get dataMessageResponse() {
    return this._dataMessageResponse;
  }

  public async sendResponse(response: string) {
    this.response.setValue(response);

    this._sendResponseRunner
      .execute(async () => {
        await this.sendDataMessage(response, this._messageId);
      })
      .catch(() => {
        // handle error in UI.
      });
  }

  public async retrySendResponse() {
    if (!this.response.getValue()) {
      throw new Error("No response to retry.");
    }

    await this.sendResponse(this.response.getValue());
  }

  public handleResponse(message: DataMessage) {
    this.presenter.onResponseReceived(message);
    this._dataMessageResponse.setValue(message);
  }

  public dispose() {
    this._sendResponseRunner.dispose();
    this._dataMessageResponse.dispose();
    this.response.dispose();
    this.presenter.dispose();
  }
}
