import { Platform } from "react-native";

export const SECOND = 1000;
export const MINUTE = SECOND * 60;

export enum StatusCodes {
  // Message failed to save in the backend over websocket.
  MESSAGE_SEND_FAILURE = 4200,
  // Agent has closed the conversation after the conversation has reached the dormant timeout.
  CLOSED_BY_AGENT = 1000,
  // When creating conversation is due to campaign being expired.
  INVALID_CHAT = 410,
  // When the conversation api key (assigned during create conversation) is invalid or expired.
  INVALID_CONVERSATION_API_KEY = 4000,
  // Used when the client closes the connection.
  CLIENT_CLOSED = 4100,
  // When the conversation can't be opened because it's outside the hours of operation
  HOUR_MISS_FAILURE = 409,
  // Conversation has been closed by backend due to being inactive for certain time (is configurable).
  INACTIVE_CONVERSATION_CLOSED_TIMEOUT = 4201,
}

export enum SessionStorageKeys {
  // The conversation api key that is assigned after successfully creating a conversation.
  CONVERSATION_ID = "tcnChatClientConversationId",
  // user information phone number
  USER_PHONE_NUMBER = "tcnChatClientUserPhoneNumber",
  // user information email address
  USER_EMAIL_ADDRESS = "tcnChatClientUserEmailAddress",
  // user information full name
  USER_FULL_NAME = "tcnChatClientUserFirstName",
  // SLA's
  CONVERSATION_T11 = "tcnChatClientT11TimeoutTimer",
  // Agent Message ID
  MESSAGE_ID = "tcnChatClientAgentMessageId",
  // color properties for the current conversation
  COLOR_PROPERTIES = "tcnChatClientColorProperties",
}

export enum ConversationStates {
  // Initial state for conversation
  IDLE = "IDLE",
  // Web socket connection is up and running.
  CONNECTED = "CONNECTED",
  // Socket connection was up at one point but closed unexpectedly.
  DISCONNECTED = "DISCONNECTED",
  // Conversation has closed as expected (ie. agent client closed the conversation on dormant timeout).
  FINISHED = "FINISHED",
  // The chat campaign that the conversation belongs to has expired. Campaign needs to be updated before another conversation can be started.
  INVALID_CAMPAIGN_EXPIRED = "INVALID_CAMPAIGN_EXPIRED",
  // The chat campaign is unavailable, because it's outside the hours of operation
  // UNAVAILABLE = "UNAVAILABLE_CAMPAIGN",
  // Conversation can't start because it's outside the hours of operation
  OPERATING_HOURS_MISS = "OPERATING_HOURS_MISS",
  // No agents were available to pick up the chat, so we tell them to come back later
  NOBODY_HOME = "NOBODY_HOME",
  // For some reason the backend did not return a configuration for the chat widget.
  NO_WIDGET_CONFIG_FOUND = "NO_WIDGET_CONFIG_FOUND",
}

export enum MessageStatus {
  SENDING = "SENDING",
  FAILED = "FAILED",
}

// The default fontFamily for web
var font = [
  "Proxima",
  "Lato",
  "Montserrat",
  "Helvetica",
  "Arial",
  "sans-serif",
].join(", ");

// Change the font family if we are on mobile native app
if (Platform.OS !== "web") {
  font = "System";
}

export const fontStyle = font;

export const maxCharCount = 1000;

export const widgetSpacing = {
  fromTop: 26, // for box shadow
  fromBottom: 18, // from edge of screen
  fromLeft: 30, // for box shadow
  fromRight: 16, // from edge of screen
};

export const chatBoxStyles = {
  width: 268,
  height: 450,
  borderRadius: 4,
  header: {
    height: 48,
  },
  footer: {
    height: 28,
  },
};

export const expandChatButtonStyles = {
  width: 38,
  height: 38,
  borderRadius: 4,
};

// top level domain reference - https://en.wikipedia.org/wiki/List_of_Internet_top-level_domains
export const topLevelDomains = [
  // Original top-level domains
  "com",
  "org",
  "net",
  "int",
  "edu",
  "gov",
  "mil",

  // Infrastructure top-level domain
  "arpa",

  // Custom top-level domains (internally added by tcn-dev team)
  "info",
  "io",
  "dev",
  "biz",
  "tech",
  "name",
  "pro",
  "store",
  "tv",
  "museum",
  "xyz",
  "online",
  "app",
  "travel",
  "jobs",
  "mobi",
  "asia",
  "cat",
  "tel",
  "post",
  "xxx",
  "pics",
  "red",
  "pink",
  "blue",
  "green",
  "black",
  "navy",

  // Country code top-level domains
  "ac",
  "ad",
  "ae",
  "af",
  "ag",
  "ai",
  "al",
  "am",
  "ao",
  "aq",
  "ar",
  "as",
  "at",
  "au",
  "aw",
  "ax",
  "az",
  "ba",
  "bb",
  "bd",
  "be",
  "bf",
  "bg",
  "bh",
  "bi",
  "bj",
  "bm",
  "bn",
  "bo",
  "bq",
  "br",
  "bs",
  "bt",
  "bw",
  "by",
  "bz",
  "ca",
  "cc",
  "cd",
  "cf",
  "cg",
  "ch",
  "ci",
  "ck",
  "cl",
  "cm",
  "cn",
  "co",
  "cr",
  "cu",
  "cv",
  "cw",
  "cx",
  "cy",
  "cz",
  "de",
  "dj",
  "dk",
  "dm",
  "do",
  "dz",
  "ec",
  "ee",
  "eg",
  "eh",
  "er",
  "es",
  "et",
  "eu",
  "fi",
  "fj",
  "fk",
  "fm",
  "fo",
  "fr",
  "ga",
  "gd",
  "ge",
  "gf",
  "gg",
  "gh",
  "gi",
  "gl",
  "gm",
  "gn",
  "gp",
  "gq",
  "gr",
  "gs",
  "gt",
  "gu",
  "gw",
  "gy",
  "hk",
  "hm",
  "hn",
  "hr",
  "ht",
  "hu",
  "id",
  "ie",
  "il",
  "im",
  "in",
  "io",
  "iq",
  "ir",
  "is",
  "it",
  "je",
  "jm",
  "jo",
  "jp",
  "ke",
  "kg",
  "kh",
  "ki",
  "km",
  "kn",
  "kp",
  "kr",
  "kw",
  "ky",
  "kz",
  "la",
  "lb",
  "lc",
  "li",
  "lk",
  "lr",
  "ls",
  "lt",
  "lu",
  "lv",
  "ly",
  "ma",
  "mc",
  "md",
  "me",
  "mg",
  "mh",
  "mk",
  "ml",
  "mm",
  "mn",
  "mo",
  "mp",
  "mq",
  "mr",
  "ms",
  "mt",
  "mu",
  "mv",
  "mw",
  "mx",
  "my",
  "mz",
  "na",
  "nc",
  "ne",
  "nf",
  "ng",
  "ni",
  "nl",
  "no",
  "np",
  "nr",
  "nu",
  "nz",
  "om",
  "pa",
  "pe",
  "pf",
  "pg",
  "ph",
  "pk",
  "pl",
  "pm",
  "pn",
  "pr",
  "ps",
  "pt",
  "pw",
  "py",
  "qa",
  "re",
  "ro",
  "rs",
  "ru",
  "rw",
  "sa",
  "sb",
  "sc",
  "sd",
  "se",
  "sg",
  "sh",
  "si",
  "sk",
  "sl",
  "sm",
  "sn",
  "so",
  "sr",
  "ss",
  "st",
  "su",
  "sv",
  "sx",
  "sy",
  "sz",
  "tc",
  "td",
  "tf",
  "tg",
  "th",
  "tj",
  "tk",
  "tl",
  "tm",
  "tn",
  "to",
  "tr",
  "tt",
  "tv",
  "tw",
  "tz",
  "ua",
  "ug",
  "uk",
  "us",
  "uy",
  "uz",
  "va",
  "vc",
  "ve",
  "vg",
  "vi",
  "vn",
  "vu",
  "wf",
  "ws",
  "ye",
  "yt",
  "za",
  "zm",
] as const;
