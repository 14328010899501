// General
import {
  Keyboard,
  KeyboardEvent,
  Dimensions,
  ScaledSize,
  Platform,
} from "react-native";

// Utils/Hooks
import { useState, useEffect } from "react";
import { useSafeAreaInsets } from "react-native-safe-area-context";

const useScreenHeight = () => {
  const [screenHeight, setScreenHeight] = useState(
    Dimensions.get("window").height
  );

  useEffect(() => {
    const onChange = ({ window }: { window: ScaledSize }): void => {
      setScreenHeight(window.height);
    };

    const changeListener = Dimensions.addEventListener("change", onChange);

    return (): void => {
      changeListener.remove();
    };
  }, []);

  return screenHeight;
};

const useKeyboardHeight = () => {
  const [keyboardHeight, setKeyboardHeight] = useState(0);

  function onKeyboardDidShow(e: KeyboardEvent): void {
    const endCoordinatesHeight = e.endCoordinates.height;
    setKeyboardHeight(endCoordinatesHeight);
  }

  function onKeyboardDidHide(): void {
    setKeyboardHeight(0);
  }

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener(
      "keyboardDidShow",
      onKeyboardDidShow
    );
    const keyboardDidHideListener = Keyboard.addListener(
      "keyboardDidHide",
      onKeyboardDidHide
    );

    return (): void => {
      keyboardDidShowListener.remove();
      keyboardDidHideListener.remove();
    };
  }, []);

  return keyboardHeight;
};

export const useAvailableScreenHeight = () => {
  const screenHeight = useScreenHeight();
  const keyboardHeight = useKeyboardHeight();
  const insets = Platform.OS === "web" ? { top: 0 } : useSafeAreaInsets();
  const { top: safeAreaTop } = insets;

  return screenHeight - keyboardHeight - safeAreaTop;
};
