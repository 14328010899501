import React from "react";
import Svg, { Path } from "react-native-svg";

export const FlowIcon = ({ fillColor }) => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 12C3.75 7.44365 7.44365 3.75 12 3.75C16.5564 3.75 20.25 7.44365 20.25 12C20.25 16.5563 16.5564 20.25 12 20.25H4.38671C4.44208 20.1609 4.49371 20.0757 4.54156 19.9941C4.76667 19.6101 4.93921 19.254 5.02614 18.8789C5.08857 18.6096 5.10413 18.4986 5.11816 18.2225C5.13874 17.8173 5.03609 17.3357 4.88542 16.7855C4.73048 16.2197 4.49527 15.4841 4.19182 14.5351L4.18591 14.5166C3.92871 13.7122 3.75 12.8456 3.75 12ZM2.25 21C2.25 20.8424 2.29861 20.6962 2.38165 20.5755C2.77429 19.9865 3.05123 19.5703 3.24752 19.2355C3.44396 18.9004 3.52846 18.6974 3.56488 18.5402C3.59038 18.4302 3.59934 18.3841 3.60482 18.345C3.6103 18.3059 3.61436 18.2592 3.62009 18.1464C3.62723 18.0059 3.58952 17.7325 3.43869 17.1817C3.29313 16.6501 3.06774 15.9447 2.75717 14.9735C2.46937 14.0734 2.25 13.0472 2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75H3C2.58579 21.75 2.25 21.4142 2.25 21ZM8 13C8.55228 13 9 12.5523 9 12C9 11.4477 8.55228 11 8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13ZM13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12ZM16 13C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11C15.4477 11 15 11.4477 15 12C15 12.5523 15.4477 13 16 13Z"
        fill={fillColor}
      />
    </Svg>
  );
};
