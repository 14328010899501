import React from "react";
import { makeContextHook } from "@src/commons/makeContextHook";

export interface IOmniChatClient {
  apiKey: string;
  url: string;
  mainColor?: string;
  headingColor?: string;
  paragraphColor?: string;
}

export interface IChatConfig {
  config: IOmniChatClient;
  children: React.ReactNode;
}

export const chatConfigContext = React.createContext<
  IOmniChatClient | undefined
>(undefined);

export const useChatConfiguration = makeContextHook(chatConfigContext);

const ChatConfigProvider: React.FC<IChatConfig> = ({ config, children }) => {
  return (
    <chatConfigContext.Provider value={config}>
      {children}
    </chatConfigContext.Provider>
  );
};

export default ChatConfigProvider;
