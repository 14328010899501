// General
import React from "react";
import { View, Text, Linking, StyleSheet } from "react-native";

// Components/Shared
import ParsedText from "@src/adaptors/parsed";

// Utils/Hooks
import { decode } from "html-entities";
import { useColorProperties } from "@src/hooks/useColorProperties";

// Types/Constants
import { topLevelDomains } from "@src/constants";

// necessary to fix jss types issue - https://github.com/cssinjs/jss/issues/1479
interface JssProps {
  sentByClient: boolean;
}

const styles = StyleSheet.create({
  root: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 16,
  },

  text: {
    padding: 8,
    marginBottom: 3,
    borderRadius: 4,
    fontSize: 10,
  },

  timeSent: {
    fontSize: 8,
    color: "#414141",
  },
});

const ChatTextMessage = ({
  sentByClient,
  sender,
  timeSent,
  message,
}: {
  sentByClient: boolean;
  sender: string;
  timeSent: number;
  message: string;
}) => {
  const handleUrlPress = (url, matchIndex /*: number*/) => {
    // links must be fully prefixed with http:// or https:// - we'll prepend https:// when needed
    const urlWithProtocol = /^https?:\/{2}/.test(url) ? url : `https://${url}`;
    Linking.openURL(urlWithProtocol);
  };
  const handleEmailPress = (emailAddress, matchIndex /*: number*/) => {
    Linking.openURL(`mailto:${emailAddress}`);
  };
  const handlePhonePress = (phoneNumber, matchIndex /*: number*/) => {
    Linking.openURL(`tel:${phoneNumber}`);
  };

  const urlRegexStr =
    "(?:https?:)?(?:\\/{2})?" + // match any protocol, optional, non-capturing groups
    "[a-zA-Z0-9]{1}" + // links must start with a letter or number
    "[-a-zA-Z0-9@:%_+.~#?&\\/=]{1,255}" + // match any character except for a dot, (up to 255, accounts for previous single character match)
    "\\." + // match a dot before the tld
    `(?:${topLevelDomains.join("|")})` + // group of tlds separated by "or" pipe, non-capturing group
    "\\b" + // word boundary
    "(?:\\/[-a-zA-Z0-9@:%_+.~#?&\\/=]*)?"; // match any type of queries, parameters and fragments after the domain, optional, non-capturing group
  const urlRegex = new RegExp(urlRegexStr, "i");

  const insertMessage = () => (
    <View style={[styles.text, sentByClientProperties.background]}>
      <ParsedText
        style={sentByClientProperties.parsedText}
        parse={[
          // Order matters here - the urlRegex must come after email because the pattern matches email addresses
          {
            type: "phone",
            style: sentByClientProperties.link,
            onPress: handlePhonePress,
          },
          {
            type: "email",
            style: sentByClientProperties.link,
            onPress: handleEmailPress,
          },
          {
            pattern: urlRegex,
            style: sentByClientProperties.link,
            onPress: handleUrlPress,
          },
        ]}
        childrenProps={{ allowFontScaling: false }}
      >
        {decode(message)}
      </ParsedText>
    </View>
  );

  const { mainColor } = useColorProperties();

  const sentByClientProperties = StyleSheet.create({
    rootAlign: {
      alignSelf: sentByClient ? "flex-end" : "flex-start",
    },
    background: {
      backgroundColor: sentByClient ? "#DFD7CD" : "#FAFAFA",
    },

    timeSentAlign: {
      alignSelf: sentByClient ? "flex-end" : "flex-start",
    },
    link: {
      color: mainColor,
      fontSize: 10,
    },
    parsedText: {
      fontSize: 10,
      maxWidth: sentByClient ? 220 : 180,
    },
  });

  return (
    <View style={[styles.root, sentByClientProperties.rootAlign]}>
      {!sentByClient && <NameInitialsAvatar name={sender} />}
      <View>
        {insertMessage()}

        <Text style={[styles.timeSent, sentByClientProperties.timeSentAlign]}>
          {new Date(timeSent).toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })}
        </Text>
      </View>
    </View>
  );
};

function getInitials(name) {
  if (!name || typeof name !== "string") {
    return "  ";
  }

  let tokens = name.split(" ");
  tokens = tokens.map((t) => t.slice(0, 1));

  if (tokens.length < 2) {
    tokens = name.slice(0, 2).split("");
  }

  return tokens[0].charAt(0) + tokens[tokens.length - 1].charAt(0);
}

const avatarStyles = StyleSheet.create({
  avatar: {
    flexShrink: 0,
    display: "flex",
    padding: 5,
    alignItems: "center",
    justifyContent: "center",
    width: 32,
    height: 32,
    fontSize: 14,
    fontWeight: "700",
    color: "#FFFFFF",
    // 30px is enough to make it a circle - react native doesn't support "50%"
    borderRadius: 30,
    marginTop: 8,
    marginRight: 8,
  },
});

const NameInitialsAvatar = ({ name }) => {
  const { mainColor, headingColor } = useColorProperties();

  return (
    <View style={[avatarStyles.avatar, { backgroundColor: mainColor }]}>
      <Text style={{ color: headingColor }}>
        {getInitials(name).toUpperCase()}
      </Text>
    </View>
  );
};

export default ChatTextMessage;
