import { ConversationStates, SessionStorageKeys } from "@src/constants";
import { IUserData } from "@src/mediators/types/IUserData";
import { sessionStorage } from "@src/adaptors/storage";

export const setUserSessionData = (
  conversationApiKey: string,
  { fullName, emailAddress, phoneNumber }: IUserData,
  customerIdleTimeBeforeClosingConvo: number
) => {
  // set conversation id into session storage - but need to include user info as well.

  if (!conversationApiKey) {
    return;
  }

  if (!fullName && !emailAddress) {
    return;
  }

  sessionStorage.setItem(
    SessionStorageKeys.CONVERSATION_ID,
    conversationApiKey
  );

  if (customerIdleTimeBeforeClosingConvo) {
    sessionStorage.setItem(
      SessionStorageKeys.CONVERSATION_T11,
      String(customerIdleTimeBeforeClosingConvo)
    );
  }

  if (fullName) {
    sessionStorage.setItem(SessionStorageKeys.USER_FULL_NAME, fullName);
  }

  if (emailAddress) {
    sessionStorage.setItem(SessionStorageKeys.USER_EMAIL_ADDRESS, emailAddress);
  }

  if (phoneNumber) {
    sessionStorage.setItem(SessionStorageKeys.USER_PHONE_NUMBER, phoneNumber);
  }
};

export const clearUserSessionData = () => {
  sessionStorage.removeItem(SessionStorageKeys.CONVERSATION_ID);
  sessionStorage.removeItem(SessionStorageKeys.USER_FULL_NAME);
  sessionStorage.removeItem(SessionStorageKeys.USER_PHONE_NUMBER);
  sessionStorage.removeItem(SessionStorageKeys.USER_EMAIL_ADDRESS);
  sessionStorage.removeItem(SessionStorageKeys.CONVERSATION_T11);
  sessionStorage.removeItem(SessionStorageKeys.MESSAGE_ID);
};

export const getUserSessionData = () => {
  const userData = {
    fullName: sessionStorage.getItem(SessionStorageKeys.USER_FULL_NAME),
    emailAddress: sessionStorage.getItem(SessionStorageKeys.USER_EMAIL_ADDRESS),

    phoneNumber: sessionStorage.getItem(SessionStorageKeys.USER_PHONE_NUMBER),
  };

  if (!userData.fullName && !userData.emailAddress) return null;

  return Object.entries(userData).reduce((acc, [key, value]) => {
    if (value) {
      acc[key] = value;
    }
    return acc;
  }, {} as IUserData);
};

export const getSessionSlaT11Timer = () => {
  return sessionStorage.getItem(SessionStorageKeys.CONVERSATION_T11);
};

export const setAgentMessageIdSessionData = (agentMessageId: string) => {
  sessionStorage.setItem(SessionStorageKeys.MESSAGE_ID, agentMessageId);
};

export const getAgentMessageIdSessionData = () => {
  return sessionStorage.getItem(SessionStorageKeys.MESSAGE_ID);
};

export const clearAgentMessageIdSessionData = () => {
  sessionStorage.removeItem(SessionStorageKeys.MESSAGE_ID);
};

export const getConversationIdFromSession = () => {
  return sessionStorage.getItem(SessionStorageKeys.CONVERSATION_ID);
};

export const setSessionColors = (jsonColors: string) => {
  return sessionStorage.setItem(
    SessionStorageKeys.COLOR_PROPERTIES,
    jsonColors
  );
};

export interface SessionColors {
  mainColor: string;
  headingColor: string;
  paragraphColor: string;
}

export const getSessionColors = (): string => {
  return sessionStorage.getItem(SessionStorageKeys.COLOR_PROPERTIES);
};

export const conversationHasEnded = (status: ConversationStates): boolean => {
  return [
    ConversationStates.FINISHED,
    ConversationStates.INVALID_CAMPAIGN_EXPIRED,
    ConversationStates.OPERATING_HOURS_MISS,
    ConversationStates.NOBODY_HOME,
  ].includes(status);
};
