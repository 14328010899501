import React from "react";
import Svg, { Path } from "react-native-svg";

export const CloseButtonSvg = ({ fillColor }) => {
  return (
    <Svg width="18" height="18" viewBox="0 0 18 18" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.588016 0.588042C0.965298 0.21076 1.57701 0.21076 1.95429 0.588042L8.99997 7.63367L16.0457 0.588042C16.423 0.21076 17.0346 0.21076 17.4119 0.588042C17.7892 0.965325 17.7892 1.57704 17.4119 1.95432L10.3663 9L17.4119 16.0457C17.7892 16.423 17.7892 17.0346 17.4119 17.4119C17.0346 17.7892 16.423 17.7892 16.0457 17.4119L8.99997 10.3663L1.95429 17.4119C1.57701 17.7892 0.965298 17.7892 0.588016 17.4119C0.210733 17.0346 0.210733 16.423 0.588016 16.0457L7.63365 9L0.588016 1.95432C0.210733 1.57704 0.210733 0.965325 0.588016 0.588042Z"
        fill={fillColor}
      />
    </Svg>
  );
};
