// General
import React, { createContext, useEffect, useState } from "react";

// Utils/Hooks
import {
  getConversationIdFromSession,
  getUserSessionData,
  getSessionSlaT11Timer,
} from "@src/utils";
import { useUpdateEffect } from "@src/commons/useUpdateEffect";
import { makeContextHook } from "@src/commons/makeContextHook";
import { useChatConfiguration } from "@src/providers/ChatConfigurationProvider";

// Types/Constants
import ChatClientApiAdapter from "@src/mediators/ChatClientApiAdapter/ChatClientApiAdapter";
import ConversationMediator, {
  ConversationMediatorContext,
} from "@src/mediators/ConversationMediator/ConversationMediator";
import { IChatClientApi } from "@src/mediators/types/IChatClientApi";

const mediatorContext = createContext<ConversationMediator | undefined>(
  undefined
);

export const useConversationMediator = makeContextHook(mediatorContext);

export interface IConversationMediatorProviderProps {
  api?: IChatClientApi;
  children: React.ReactNode;
}

const ConversationMediatorProvider: React.FC<
  IConversationMediatorProviderProps
> = ({ api, children }) => {
  const appConfig = useChatConfiguration();
  // TODO: adjust these to be mock data when done testing
  const url = appConfig?.url ?? "chat.dev.tcn.com";
  const apiKey = appConfig?.apiKey ?? "";

  // States

  // Api
  const [chatApi, setChatApi] = useState<IChatClientApi>(() =>
    api ? api : new ChatClientApiAdapter(apiKey, url)
  );

  // Session storage data
  const userData = getUserSessionData();
  const conversationApiKey = getConversationIdFromSession();
  const customerIdleTimeBeforeClosingConvo = getSessionSlaT11Timer();

  const config: ConversationMediatorContext = {
    api: chatApi,
  };

  // Add session data if found.
  if (conversationApiKey && userData) {
    config.sessionConversationId = conversationApiKey;
    config.sessionUserData = userData;
  }

  // Add SLA T11 timer session data
  if (customerIdleTimeBeforeClosingConvo) {
    config.sessionSlaT11Timer = Number(customerIdleTimeBeforeClosingConvo);
  }

  // Chat Manager
  const [domain, setDomain] = useState<ConversationMediator>(() => {
    const domain = new ConversationMediator(config);
    return domain;
  });

  // Api
  useUpdateEffect(() => {
    setChatApi(api ? api : new ChatClientApiAdapter(apiKey, url));
  }, [api]);

  // Chat Manager
  useUpdateEffect(() => {
    const config: ConversationMediatorContext = {
      api: chatApi,
    };

    // Add session data if found.
    if (conversationApiKey && userData) {
      config.sessionConversationId = conversationApiKey;
      config.sessionUserData = userData;
    }

    const domain = new ConversationMediator(config);
    setDomain(domain);
  }, [chatApi]);

  useEffect(() => {
    domain.initialize();
    () => {
      return domain.dispose();
    };
  }, [domain]);

  return (
    <mediatorContext.Provider value={domain}>
      {children}
    </mediatorContext.Provider>
  );
};

export default ConversationMediatorProvider;
