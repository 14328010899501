import { Platform } from "react-native";
import { ObservableValue } from "@src/hex/observable_value";
import {
  ResponseValues,
  createDom,
  stringifyDocument,
} from "@src/components/flows/flow_message/utils";
import { DataMessage } from "@src/mediators/types/IProtoChatMessage";
import { scriptString } from "@src/components/flows/flow_message/script";
import { styleSheetString } from "@src/components/flows/flow_message/styles";
import { ColorProperties } from "@src/mediators/ChatClientApiAdapter/apiTypes";

export interface FlowMessagePresenterPort {
  originalHtml: string;
  updatedHtml: ObservableValue<string>;
  platform: string;
  colorProperties: ColorProperties | undefined;
  onResponseReceived(response: DataMessage): void;
  dispose: () => void;
}

export class FlowMessagePresenter implements FlowMessagePresenterPort {
  public platform: string;
  public originalHtml: string;
  public updatedHtml: ObservableValue<string>;
  public colorProperties: ColorProperties | undefined;

  constructor(
    originalHtml: string,
    colorProperties: ColorProperties | undefined
  ) {
    this.originalHtml = originalHtml;
    this.updatedHtml = new ObservableValue<string>("");
    this.platform = Platform.OS;
    this.colorProperties = colorProperties;

    this._initialize();
  }

  private _initialize() {
    if (this.platform === "web") {
      this._initialHtmlBuildForWebview();
      return;
    }

    this._initialHtmlBuildForNative();
  }

  public onResponseReceived(response: DataMessage) {
    const responseObj: ResponseValues = JSON.parse(response.message);

    if (responseObj) {
      this.platform === "web" && this._rebuildHtmlAfterResponse(responseObj);
      return;
    }

    throw new Error("No response value found in message.");
  }

  private _initialHtmlBuildForWebview() {
    const doc = createDom(this.originalHtml);

    // Add styles
    const styleElement: HTMLStyleElement = document.createElement("style");
    styleElement.textContent = styleSheetString(this.colorProperties);
    doc.head.appendChild(styleElement);

    // Add script
    const scriptElement: HTMLScriptElement = document.createElement("script");
    scriptElement.textContent = scriptString(this.platform);
    doc.body.appendChild(scriptElement);

    this.updatedHtml.setValue(stringifyDocument(doc));
  }

  private _initialHtmlBuildForNative() {
    const doc = `<!DOCTYPE html>
                  <html>
                    <head>
                      <title>Messaging</title>
                      <meta http-equiv="content-type" content="text/html; charset=utf-8">
                      <meta name="viewport" content="width=240, user-scalable=no">
                      <style>${styleSheetString(this.colorProperties)}</style>
                    </head>
                    <body>
                      ${this.originalHtml}
                    </body>
                  </html>`;
    this.updatedHtml.setValue(doc);
  }

  private _rebuildHtmlAfterResponse(values: ResponseValues) {
    const doc = createDom(this.updatedHtml.getValue());
    const formNodes = doc.querySelectorAll("form");

    if (!formNodes.length) {
      this._initialHtmlBuildForWebview();
      return;
    }

    // Fill in the form elements with the response values if they don't have a value
    formNodes.forEach((formNode) => {
      Object.values(formNode.elements).forEach(
        (element: HTMLInputElement | HTMLSelectElement | HTMLButtonElement) => {
          if (element instanceof HTMLInputElement) {
            if (element.type === "checkbox") {
              values[element.name] && element.setAttribute("checked", "true");
              element.setAttribute("disabled", "true");
            } else if (element.type === "radio") {
              values[element.name] &&
                values[element.name] == element.value &&
                element.setAttribute("checked", "true");
              element.setAttribute("disabled", "true");
            } else {
              element.setAttribute("value", values[element.name] as string);
              element.setAttribute("disabled", "true");
            }
          }

          if (element instanceof HTMLSelectElement) {
            element.setAttribute("value", values[element.name] as string);

            const options = element.querySelectorAll("option");
            options.forEach((option) => {
              if (option.getAttribute("value") === values[element.name]) {
                option.setAttribute("selected", "true");
                element.setAttribute("disabled", "true");
              }
            });
          }

          if (
            element instanceof HTMLButtonElement &&
            !element.getAttribute("value")
          ) {
            element.setAttribute("disabled", "true");
            return;
          }

          if (element instanceof HTMLButtonElement) {
            if (element.getAttribute("value") === Object.values(values)[0]) {
              element.setAttribute("disabled", "true");
              element.setAttribute("class", "active");
            } else {
              element.remove();
            }
          }
        }
      );
    });
    this.updatedHtml.setValue(stringifyDocument(doc));
  }

  public dispose() {
    this.updatedHtml.dispose();
  }
}
