import React from "react";
import { View, StyleSheet, Platform } from "react-native";

// Types
import { FlowMessageDomain } from "@src/mediators/ConversationFlow/flow_message_domain";

// Components
import { WebViewWeb } from "@src/components/flows/flow_message/webview/webview_web";
import { WebViewNative } from "@src/components/flows/flow_message/webview/webview_native";

const shiftTheFlowMessageUpIfWeb =
  Platform.OS === "web" ? "translateY(-12px)" : "";

const styles = StyleSheet.create({
  flow: {
    marginBottom: 16,
    transform: shiftTheFlowMessageUpIfWeb,
  },
  webviewMessage: {
    display: "flex",
    flexDirection: "row",
  },
  avatar: {
    flexShrink: 0,
    display: "flex",
    padding: 5,
    alignItems: "center",
    justifyContent: "center",
    width: 32,
    height: 32,
    fontSize: 14,
    color: "#FFFFFF",
    // 30px is enough to make it a circle - react native doesn't support "50%"
    borderRadius: 30,
    marginTop: 8,
    position: "relative",
    zIndex: 3,
  },
});

interface FlowMessageProps {
  messageId: string;
  flowDomain?: FlowMessageDomain;
}

export const FlowMessage = ({ flowDomain, messageId }: FlowMessageProps) => {
  if (!flowDomain) return null;

  return (
    <View style={styles.flow}>
      <View style={styles.webviewMessage}>
        {Platform.OS === "web" ? (
          <WebViewWeb messageId={messageId} flowDomain={flowDomain} />
        ) : (
          <WebViewNative messageId={messageId} flowDomain={flowDomain} />
        )}
      </View>
    </View>
  );
};
