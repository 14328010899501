// General
import React from "react";
import { Text, StyleSheet } from "react-native";

// Components/Shared
import ChatTextMessage from "@src/components/ChatTextMessage";
import { FlowQuestion } from "@src/components/flows/question";
import { FlowMessage } from "@src/components/flows/message";

// Types/Constants
import { IMessagePayload } from "@src/mediators/types/IMessagePayload";
import { FlowMessageDomain } from "@src/mediators/ConversationFlow/flow_message_domain";
import { ChatSenderType } from "@src/mediators/types/IProtoChatMessage";

const styles = StyleSheet.create({
  chatInfoMessage: {
    padding: 8,
    fontSize: 10,
    textAlign: "center",
    color: "#AAAAAA",
    fontWeight: "700",
  },
});

const InfoMessage = ({ children }: { children: React.ReactNode }) => {
  return (
    <Text style={styles.chatInfoMessage}>
      <>{children}</>
    </Text>
  );
};

const UnassignedMessage = () => (
  <InfoMessage>We're transferring your chat to another agent...</InfoMessage>
);

const AssignmentMessage = ({ name }: { name: string }) => (
  <InfoMessage>{name} has joined the chat</InfoMessage>
);

interface ChatMessageProps {
  message: IMessagePayload;
  flowDomain?: FlowMessageDomain;
}

const ChatMessage = ({ message, flowDomain }: ChatMessageProps) => {
  const { sender, timeSent, payload, userId, messageFormat, senderType } =
    message;

  switch (true) {
    case payload.unassignConversation != undefined:
      return <UnassignedMessage />;

    case payload.reassignment != undefined:
      return (
        <React.Fragment>
          <UnassignedMessage />
          <AssignmentMessage
            name={
              getCapitalizedFirstName(payload.reassignment?.newUserName) ||
              "An agent"
            }
          />
        </React.Fragment>
      );

    case payload.assignConversation != undefined:
      return (
        <AssignmentMessage
          name={
            getCapitalizedFirstName(payload.assignConversation?.userName) ||
            "An agent"
          }
        />
      );

    case payload.textMessage != undefined &&
      messageFormat == undefined &&
      senderType != ChatSenderType.OMNI_SENDER_TYPE_FLOW:
      return (
        <ChatTextMessage
          sentByClient={userId == undefined}
          sender={sender}
          timeSent={timeSent}
          message={payload.textMessage?.message || ""}
        />
      );

    case payload.textMessage != undefined &&
      messageFormat == undefined &&
      senderType == ChatSenderType.OMNI_SENDER_TYPE_FLOW:
      return <FlowQuestion message={payload.textMessage?.message || ""} />;

    case messageFormat != undefined && flowDomain != undefined:
      return <FlowMessage flowDomain={flowDomain} messageId={message.id} />;

    default:
      return null;
  }
};

function getFirstName(name: string) {
  return name.split(" ")[0];
}

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getCapitalizedFirstName(name?: string) {
  return name ? capitalizeFirstLetter(getFirstName(name)) : "";
}

export default ChatMessage;
