// General
import React from "react";
import { View, StyleSheet } from "react-native";

// Components/Shared
import { ChatFooterSvg } from "@src/assets/chatFooter";

const styles = StyleSheet.create({
  chatFooter: {
    flexShrink: 0,
    height: 28,
    minHeight: 28,
    maxHeight: 28,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: 0,
  },
});

const ChatFooter: React.FC = () => {
  return (
    <View style={styles.chatFooter}>
      <ChatFooterSvg />
    </View>
  );
};

export default ChatFooter;
