// General
import React from "react";
import { View, Pressable, Text, Linking, StyleSheet } from "react-native";

// Components/Shared
import { UnavailableBanner } from "@src/assets/unavailableBanner";

// Utils/Hooks
import { useClientEmail } from "@src/hooks/useClientEmail";
import { useColorProperties } from "@src/hooks/useColorProperties";

// Types/Constants
import { fontStyle } from "@src/constants";

const styles = StyleSheet.create({
  chatUnavailable: {
    textAlign: "center",
    height: 280,
    width: "100%",
  },
  chatUnavailableBanner: {
    height: "85%",
    position: "relative",
  },
  chatMessageWrapper: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 10,
    paddingBottom: 18,
    paddingRight: 10,
    paddingLeft: 10,
  },
  bannerHeading: {
    fontWeight: "400",
    fontSize: 18,
    zIndex: 1,
    marginTop: 10,
    marginBottom: 0,
    fontFamily: fontStyle,
  },
  bannerIntro: {
    fontWeight: "400",
    fontSize: 14,
    marginTop: 10,
    marginBottom: 0,
    fontFamily: fontStyle,
    zIndex: 1,
  },
  bannerImage: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
  },
  smallOr: {
    fontSize: 10,
    padding: 3,
  },
  emailButton: {
    display: "flex",
    justifyContent: "center",
    height: 30,
    borderRadius: 4,
    width: 164,
    color: "white",
    // TODO There is no hover on mobile devices (there are no cursors) if we want to use hover, we can pull out styles into their own files and conditionally import the correct one
    // "&:hover": {
    //   // TODO boxShadow doesnt work in native
    //   // boxShadow: "2px 2px #DDD",
    //   shadowRadius: 2,
    //   shadowColor: "#DDD",
    // },
  },
  footerWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: -8,
  },
});

interface textProps {
  bannerHeading?: string;
}
const genericBanner = "Chat is currently unavailable";
const bannerIntro = "We are sorry";

const ChatUnavailable = ({ bannerHeading }: textProps) => {
  bannerHeading = bannerHeading ?? genericBanner;
  const clientEmail = useClientEmail();
  const { mainColor, headingColor } = useColorProperties();
  const chatUnavailableColorProperties = StyleSheet.create({
    textColor: {
      color: mainColor,
    },
    headingColor: {
      color: headingColor,
    },
    backgroundColor: {
      backgroundColor: mainColor,
    },
  });

  return (
    <React.Fragment>
      <View style={styles.chatUnavailable}>
        <View style={[styles.chatUnavailableBanner, styles.chatMessageWrapper]}>
          <UnavailableBanner />
          <Text
            style={[
              styles.bannerIntro,
              chatUnavailableColorProperties.textColor,
            ]}
          >
            {bannerIntro}
          </Text>
          <Text
            style={[
              styles.bannerHeading,
              chatUnavailableColorProperties.textColor,
            ]}
          >
            {bannerHeading}
          </Text>
        </View>
        {clientEmail !== "" && (
          <View style={styles.footerWrapper}>
            <View style={styles.smallOr}>{"or"}</View>
            <Pressable
              onPress={() => Linking.openURL(`mailto:${clientEmail}`)}
              style={[
                styles.emailButton,
                chatUnavailableColorProperties.backgroundColor,
              ]}
            >
              <Text style={[chatUnavailableColorProperties.headingColor]}>
                Send an Email
              </Text>
            </Pressable>
          </View>
        )}
      </View>
    </React.Fragment>
  );
};

export default ChatUnavailable;
