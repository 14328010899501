export interface ISubscribeToConversationRequestParams {
  apiKey: string;
  fullName: undefined | string;
  phoneNumber: undefined | string;
  emailAddress: undefined | string;
  // This is really the last message time stamp, but calling it first message time stamp to match the backend.
  firstMessageTimestamp: number;
}

// WSS WebSocket SubScribe Conversation Endpoint
export const subscribeToConversation = (
  url: string,
  requestParams: ISubscribeToConversationRequestParams
) => {
  const { apiKey, ...rest } = requestParams;
  let subscribeUrl = `${connectWss(url)}/api/v1/conversation?api_key=${apiKey}`;

  // Concat values that are defined to the url.
  Object.entries(rest).forEach(([key, value]) => {
    if (value) {
      subscribeUrl = subscribeUrl.concat(
        `&${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      );
    }
  });

  return subscribeUrl;
};

// WS Mock WebSocket SubScribe Conversation Endpoint
export const subscribeToMockConversation = (url: string) => {
  return `ws://${url}/api/v1/conversation`;
};

//Create Chat Conversation
export const createChatConversation = (url: string, apiKey: string) => {
  return `${connectHttp(url)}/api/v1/conversation?api_key=${apiKey}`;
};

//Ping -- returns a json encoded boolean to determine if this conversation
// is accepting new conversations at this moment (not paused or outside hours)
export const ping = (url: string, apiKey: string) => {
  return `${connectHttp(url)}/api/v1/ping?api_key=${apiKey}&no-cache=${Date.now()}`;
}

// connectWss - if the url begins with `http://` will strip the prefix and prepend `wss://`,
// otherwise will prepend the prefix `wss://`
// this is mainly used to connect to a local backend instance
const connectWss = (url: string) => {
  if (url.startsWith('http://')) {
    return `ws://${url.slice(7)}`
  }
  return `wss://${url}`
}

// connectHttp - if the url begins with `http://` will return the url as is, otherwise will prepend the prefix `https://`
// this is mainly used to connect to a local backend instance
const connectHttp = (url: string) => {
  if (url.startsWith('http://')) {
    return url
  }
  return `https://${url}`
}