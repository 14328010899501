import {
  convertStyleSheetToString,
  StyleSheet,
} from "@src/components/flows/flow_message/utils";
import { ColorProperties } from "@src/mediators/ChatClientApiAdapter/apiTypes";
import { getSessionColors } from "@src/utils";

export const defaultColors: ColorProperties = {
  mainColor: "#669EB4",
  headingColor: "#ffffff",
  paragraphColor: "#414141",
};

const promptMessageStyleSheet = (
  colorProperties: ColorProperties | undefined
): StyleSheet => {
  const getColors = () => {
    const colors = getSessionColors();
    if (colors) {
      return JSON.parse(colors);
    }
    return colorProperties;
  };

  return {
    body: {
      "margin-top": "0",
      "margin-left": "40px",
      "margin-bottom": "0",
      "font-family": "Proxima, Lato, Montserrat, Helvetica, Arial, sans-serif",
    },
    form: {
      display: "flex",
      "flex-direction": "row",
      "flex-wrap": "wrap",
      "grid-gap": "4px",
      "align-items": "center",
      width: "170px",
    },
    button: {
      "background-color": "transparent",
      "font-size": "10px",
      padding: "4px 16px",
      border: `1px solid ${getColors()?.mainColor ?? defaultColors.mainColor}`,
      color: getColors()?.mainColor ?? defaultColors.mainColor,
      "border-radius": "4px",
      cursor: "pointer",
      position: "relative",
    },
    "button:not([value])": {
      "margin-top": "16px",
    },
    "button:disabled, button:disabled:hover, button:not([value]):disabled": {
      "background-color": "#d9d9d9",
      cursor: "default",
      border: "1px solid #d9d9d9",
      color: "#ffffff",
    },
    "button:hover, button:focus, button:active, button.active, button:not([value])":
      {
        "background-color": getColors()?.mainColor ?? defaultColors.mainColor,
        color: getColors()?.headingColor ?? "#ffffff",
        border: `1px solid ${
          getColors()?.mainColor ?? defaultColors.mainColor
        }`,
      },
    "button:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
    },
    "input::placeholder": {
      color: "#D3D3D3",
    },
    "input:disabled::placeholder": {
      color: "#D3D3D3",
      opacity: "0.8",
    },
    'input[type="text"], select': {
      height: "24px",
      width: "100%",
      "border-color": "#d9d9d9",
      "border-style": "solid",
      "border-width": "1px",
      "border-radius": "4px",
      "font-size": "11px",
      padding: "0 8px",
      outline: "none",
      "background-color": "#ffffff",
      color: "#414141",
    },
    'input[type="text"]:focus:not(:focus-visible), select:focus:not(:focus-visible)':
      {
        outline: "none",
      },
    'input[type="text"]:focus-visible, select:focus-visible': {
      border: "1px solid #AAAAAA",
      boxShadow: "0px 0px 7px #AAAAAA",
    },
    'input[type="text"]:disabled, select:disabled': {
      "border-color": "#D3D3D3",
      "background-color": "#FAFAFA",
    },
    'input[type="radio"], input[type="checkbox"]': {
      appearance: "none",
      margin: "0",
      color: getColors()?.mainColor ?? defaultColors.mainColor,
      width: "16px",
      height: "16px",
      border: `1px solid ${getColors()?.mainColor ?? defaultColors.mainColor}`,
      display: "grid",
      "place-items": "center",
      "font-size": "10px",
      position: "relative",
    },
    'input[type="radio"]:disabled, input[type="checkbox"]:disabled': {
      border: "1px solid #D3D3D3",
    },
    'input[type="radio"]': {
      "background-color": "#ffffff",
      "border-radius": "50%",
    },
    'input[type="radio"]::before': {
      content: '""',
      width: "10px",
      height: "10px",
      "border-radius": "50%",
      transform: "scale(0)",
      "box-shadow": `inset 1em 1em ${
        getColors()?.mainColor ?? defaultColors.mainColor
      }`,
    },
    'input[type="radio"]:disabled::before': {
      "box-shadow": "inset 1em 1em #D3D3D3",
    },
    'input[type="radio"]:checked::before': {
      transform: "scale(1)",
    },
    'input[type="radio"]:focus-visible:checked': {
      outline: "none",
    },
    'input[type="radio"]:focus-visible': {
      outline: "none",
      border: `1px dashed ${getColors()?.mainColor ?? defaultColors.mainColor}`,
    },
    'input[type="checkbox"]:focus': {
      border: `1px dashed ${getColors()?.mainColor ?? defaultColors.mainColor}`,
    },
    'input[type="checkbox"]:focus:checked': {
      border: `1px solid ${getColors()?.mainColor ?? defaultColors.mainColor}`,
    },
    'input[type="checkbox"]:focus-visible': {
      outline: "none",
    },
    'input[type="checkbox"]': {
      "border-radius": "2px",
    },
    'input[type="checkbox"]::before': {
      content: '""',
      height: "8px",
      width: "3px",
      display: "none",
      transform: "rotate(45deg)",
      position: "relative",
      "z-index": "2",
    },
    'input[type="checkbox"]:checked::after': {
      content: '""',
      position: "absolute",
      top: "0",
      right: "0",
      bottom: "0",
      left: "0",
      width: "100%",
      height: "100%",
      "background-color": getColors()?.mainColor ?? defaultColors.mainColor,
    },
    'input[type="checkbox"]:checked::before': {
      display: "inline-block",
      "border-bottom": "1px solid #FFFFFF",
      "border-right": "1px solid #FFFFFF",
    },
    'input[type="checkbox"]:disabled:checked::after': {
      "background-color": "#D3D3D3",
    },
    select: { padding: "0" },
    label: {
      "font-size": "10px",
      "text-align": "left",
      "font-weight": "400",
      width: "100%",
      display: "flex",
      "flex-direction": "row",
      "grid-gap": "6px",
      "align-items": "center",
      color: "#414141",
    },
    "label:not(:first-child)": {
      "margin-top": "8px",
    },
    fieldset: {
      border: "none",
      margin: "8px 0 0",
      padding: "0",
    },
    legend: {
      "font-size": "10px",
      "text-align": "left",
      "font-weight": "400",
      padding: "0",
    },
  };
};

export const styleSheetString = (
  colorProperties: ColorProperties | undefined
): string =>
  convertStyleSheetToString(promptMessageStyleSheet(colorProperties));
