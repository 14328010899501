// General
import React from "react";
import { Text, StyleSheet } from "react-native";

const styles = StyleSheet.create({
  error: {
    fontSize: 12,
    color: "#ff0033",
    marginBottom: 10,
  },
});

interface ErrorMessageTextProps {
  errorMessage: string;
}

const ErrorMessageText = ({ errorMessage }: ErrorMessageTextProps) => {
  return <Text style={styles.error}>{errorMessage}</Text>;
};
export default ErrorMessageText;
