// General
import React from "react";
import { View, Text, Pressable, StyleSheet } from "react-native";

// Components/Shared
import { CloseButtonSvg } from "@src/assets/chatHeader";

// Utils/Hooks
import { useConversationMediator } from "@src/providers/ConversationMediatorProvider";
import { useColorProperties } from "@src/hooks/useColorProperties";

// Types/Constants
import { fontStyle } from "@src/constants";

const styles = StyleSheet.create({
  chatHeader: {
    flexShrink: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 9,
    paddingBottom: 7,
    paddingLeft: 20,
    paddingRight: 14,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    fontFamily: fontStyle,
  },

  headerTexts: {
    display: "flex",
    flex: 1,
  },

  chatHeading: {
    fontSize: 14,
    lineHeight: 14,
    fontWeight: "700",
    margin: 0,
    marginBottom: 0,
    textAlign: "left",
    padding: 0,
    fontFamily: fontStyle,
  },

  chatSubHeading: {
    fontSize: 10,
    lineHeight: 12.5,
    fontWeight: "400",
  },

  headerButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "center",
  },

  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 32,
    height: 32,
    textAlign: "center",
    lineHeight: 0,
  },

  minimizeIcon: {
    marginTop: -12,
  },
});

interface IChatHeading {
  heading: string;
  subHeading?: string;
  isForm?: boolean;
}

const ChatHeader: React.FC<IChatHeading> = ({
  heading,
  subHeading,
  isForm,
}) => {
  const { mainColor, headingColor } = useColorProperties();
  const mediator = useConversationMediator();

  const colorProperties = StyleSheet.create({
    colors: {
      backgroundColor: mainColor,
      color: headingColor,
    },
    headers: {
      color: headingColor,
    },
  });

  return (
    <View style={[styles.chatHeader, colorProperties.colors]}>
      <View style={styles.headerTexts}>
        <Text
          numberOfLines={1}
          style={[styles.chatHeading, colorProperties.headers]}
        >
          {heading}
        </Text>
        {subHeading && (
          <Text
            numberOfLines={1}
            style={[
              styles.chatHeading,
              styles.chatSubHeading,
              colorProperties.headers,
            ]}
          >
            {subHeading}
          </Text>
        )}
      </View>

      <View style={styles.headerButtons}>
        <Pressable
          onPress={() => mediator.collapseWidget()}
          style={styles.buttonContainer}
        >
          <Text style={[colorProperties.colors, styles.minimizeIcon]}>__</Text>
        </Pressable>
        {!isForm && (
          <Pressable
            style={[colorProperties.colors, styles.buttonContainer]}
            onPress={() => mediator.handleEndChat()}
          >
            <CloseButtonSvg fillColor={headingColor} />
          </Pressable>
        )}
      </View>
    </View>
  );
};

export default ChatHeader;
