// General
import React from "react";
import { View, Pressable, Text, StyleSheet } from "react-native";

// Utils/Hooks
import { useConversationMediator } from "@src/providers/ConversationMediatorProvider";
import { useColorProperties } from "@src/hooks/useColorProperties";

// Types/Constants
import { chatBoxStyles } from "@src/constants";

const styles = StyleSheet.create({
  confirmationScreen: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height:
      chatBoxStyles.height -
      chatBoxStyles.header.height -
      chatBoxStyles.footer.height,
  },
  topText: {
    flexShrink: 0,
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 60,
    marginBottom: 90,
  },
  buttons: {
    flexShrink: 0,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "auto",
  },
});

const EndChatConfirmation = () => {
  const mediator = useConversationMediator();

  const { mainColor } = useColorProperties();
  const colorProperties = StyleSheet.create({
    endChat: {
      fontSize: 14,
      lineHeight: 18.2,
      color: mainColor,
      marginBottom: 12,
    },
    subText: {
      width: "70%",
      textAlign: "center",
      fontSize: 10,
      lineHeight: 12.5,
      color: "#aaaaaa",
    },
    endButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: 40,
      width: "80%",
      maxWidth: 198,
      marginBottom: 8,
      borderColor: mainColor,
      borderWidth: 1,
      borderRadius: 4,
    },
    goBackButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: 40,
      width: "80%",
      maxWidth: 198,
    },
    buttonText: {
      alignSelf: "center",
      fontSize: 10,
      color: mainColor,
    },
  });

  return (
    <View style={styles.confirmationScreen}>
      <View style={styles.topText}>
        <Text style={colorProperties.endChat}>End Chat?</Text>
        <Text style={colorProperties.subText}>
          When you select End Conversation your chat session will end
        </Text>
      </View>
      <View style={styles.buttons}>
        <Pressable
          style={colorProperties.endButton}
          onPress={() => {
            mediator.confirmEndChat();
          }}
        >
          <Text style={colorProperties.buttonText}>End Conversation</Text>
        </Pressable>
        <Pressable
          onPress={() => {
            mediator.setShowEndChatConfirmation(false);
          }}
          style={colorProperties.goBackButton}
        >
          <Text style={colorProperties.buttonText}>Go Back</Text>
        </Pressable>
      </View>
    </View>
  );
};

export default EndChatConfirmation;
