// General
import React from "react";
import { Platform, Pressable, ViewStyle } from "react-native";

// Components/Shared
import { ChatSvg } from "@src/assets/chat";

// Utils/Hooks
import { useColorProperties } from "@src/hooks/useColorProperties";

// Types/Constants
import { widgetSpacing, expandChatButtonStyles } from "@src/constants";

interface ChatButtonProps {
  disabled?: boolean;
  handleClick: () => void;
}

const ChatButton = ({ disabled, handleClick }: ChatButtonProps) => {
  const { mainColor } = useColorProperties();

  const shadowStyle: ViewStyle =
    Platform.OS === "ios"
      ? {
          shadowColor: "black",
          shadowOffset: { width: 0, height: 4 },
          shadowOpacity: 0.2,
          shadowRadius: 3.8,
        }
      : {
          elevation: 5,
          shadowColor: "black",
        };
  const $expandChatBoxStyle: ViewStyle = {
    width: expandChatButtonStyles.width,
    height: expandChatButtonStyles.height,
    marginTop: "auto",
    marginBottom: widgetSpacing.fromBottom,
    marginLeft: widgetSpacing.fromLeft,
    marginRight: widgetSpacing.fromRight,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // TODO should have a better default than gray. putting here for now. It is needed because mainColor can be empty (happened when out of hours of operation or maybe api was down)
    backgroundColor: mainColor || "#828282",
    borderRadius: expandChatButtonStyles.borderRadius,
    ...shadowStyle,
  };

  return (
    <Pressable
      nativeID="tcn-chat-button"
      style={$expandChatBoxStyle}
      onPress={handleClick}
      disabled={disabled}
    >
      <ChatSvg />
    </Pressable>
  );
};

export default ChatButton;
