// General
import React, { useRef } from "react";
import { View, ScrollView, Pressable, Text, StyleSheet } from "react-native";

// Components/Shared
import ChatMessage from "@src/components/ChatMessage";
import ChatReply from "@src/components/ChatReply";
import EndChatConfirmation from "@src/components/EndChatConfirmation";

// Utils/Hooks
import { useConversationMediator } from "@src/providers/ConversationMediatorProvider";
import { useConversationStatus } from "@src/hooks/useConversationStatus";
import { useUser } from "@src/hooks/useUser";
import { useConversationMessages } from "@src/hooks/useConversationMessages";
import { useColorProperties } from "@src/hooks/useColorProperties";
import { ConversationStates, chatBoxStyles } from "@src/constants";

// Types/Constants
import { IUserData } from "@src/mediators/types/IUserData";
import { useAsyncValue } from "@src/hex/use_async_value";

const styles = StyleSheet.create({
  chatConversation: {
    display: "flex",
    flexShrink: 1,
    height:
      chatBoxStyles.height -
      chatBoxStyles.header.height -
      chatBoxStyles.footer.height,
    marginTop: "auto",
  },

  chatConversationMessages: {
    flexShrink: 1,
    flexGrow: 1,
    marginTop: "auto",
    paddingTop: 0,
    paddingBottom: 8,
    paddingLeft: 8,
    paddingRight: 8,
  },

  chatEnded: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 16,
  },

  chatEndedInner: {
    display: "flex",
    flexDirection: "row",
    marginTop: 2,
  },

  chatEndedText: {
    fontWeight: "bold",
    fontSize: 10,
    lineHeight: 12.5,
    color: "#AAAAAA",
  },
});

const ChatConversation = () => {
  const messages = useConversationMessages();
  const user: IUserData | null = useUser();

  const scrollViewRef = useRef<ScrollView | null>(null);

  const { mainColor } = useColorProperties();

  const conversationStatus = useConversationStatus();

  const mediator = useConversationMediator();

  const flowDomains = useAsyncValue(mediator.flowDomains);

  const showEndChatConfirmation = useAsyncValue(
    mediator.showEndChatConfirmation
  );

  // necessary for react style prop types (unknown css property)
  const customPropertyStyles = StyleSheet.create({
    chatConversation: {
      color: mainColor,
    },
    restart: {
      color: "#CD6B2C",
    },
  });

  return showEndChatConfirmation ? (
    <EndChatConfirmation />
  ) : (
    <View style={styles.chatConversation}>
      <ScrollView
        style={styles.chatConversationMessages}
        ref={scrollViewRef}
        nestedScrollEnabled
        contentContainerStyle={{ flexGrow: 1, justifyContent: "flex-end" }}
        onContentSizeChange={() => scrollViewRef.current?.scrollToEnd()}
      >
        {/* Adding a spacer to act as padding */}
        <View style={{ height: 8 }} />
        {messages.map((message) => (
          <ChatMessage
            key={
              message.payload.dataMessage ? message.uiReferenceId : message.id
            }
            message={message}
            flowDomain={flowDomains.get(message.id)}
          />
        ))}
      </ScrollView>

      {/* after a conversation ends the user data is cleared and the state is IDLE
        but we still want to show their conversation thread until they get back to the form */}
      {!user || conversationStatus === ConversationStates.FINISHED ? (
        <View style={styles.chatEnded}>
          <Text style={styles.chatEndedText}>Your session has ended.</Text>
          <View style={styles.chatEndedInner}>
            <Text style={styles.chatEndedText}>To start a new chat, </Text>
            <Pressable onPress={() => mediator.resetAfterChatEnd()}>
              <Text
                style={[styles.chatEndedText, customPropertyStyles.restart]}
              >
                click here
              </Text>
            </Pressable>
            <Text style={styles.chatEndedText}>.</Text>
          </View>
        </View>
      ) : (
        <ChatReply />
      )}
    </View>
  );
};

export default ChatConversation;
