import React from "react";
import { View, StyleSheet, Text } from "react-native";

// Hooks
import { useColorProperties } from "@src/hooks/useColorProperties";
import { FlowIcon } from "@src/assets/flow.";

const styles = StyleSheet.create({
  flowQuestion: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 16,
  },
  textWrapper: {
    flex: 1,
  },
  text: {
    padding: 8,
    marginBottom: 3,
    borderRadius: 4,
    fontSize: 10,
    backgroundColor: "#FAFAFA",
    display: "flex",
    alignItems: "center",
    height: "auto",
  },
  avatar: {
    flexShrink: 0,
    display: "flex",
    padding: 5,
    alignItems: "center",
    justifyContent: "center",
    width: 32,
    height: 32,
    fontSize: 14,
    color: "#FFFFFF",
    // 30px is enough to make it a circle - react native doesn't support "50%"
    borderRadius: 30,
    marginTop: 8,
    marginRight: 8,
  },
});

interface FlowQuestionProps {
  message: string;
}

export const FlowQuestion = ({ message }: FlowQuestionProps) => {
  const { headingColor, mainColor } = useColorProperties();

  return (
    <View style={styles.flowQuestion}>
      <View style={[styles.avatar, { backgroundColor: mainColor }]}>
        <FlowIcon fillColor={headingColor} />
      </View>
      <View style={styles.textWrapper}>
        <Text style={styles.text}>{message}</Text>
      </View>
    </View>
  );
};
