// General
import React from "react";
import { Text, StyleSheet } from "react-native";

// Types/Constants
import { maxCharCount } from "@src/constants";

const styles = StyleSheet.create({
  remaining: {
    position: "absolute",
    bottom: 0,
    left: 0,
    marginLeft: 8,
    marginBottom: 16,
    fontSize: 12,
  },
  warning: {
    color: "#ff0033",
  },
});

interface countProps {
  length: number;
}
// display the character limit when reacning greater than 800 characters
// if they have reached the hard limit (maxCharCount) then change the color of the text to red
const DisplayCount = ({ length }: countProps) => {
  if (length <= 800) return <React.Fragment />;
  const showTooltip = length > maxCharCount;

  return (
    <Text
      style={
        length > 1000 ? [styles.remaining, styles.warning] : styles.remaining
      }
    >
      {length}/{maxCharCount}
    </Text>
  );
};

export default DisplayCount;
