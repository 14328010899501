const increaseSpecificity = (
  repeat: number = 3,
  selector: string = ":not(#\\20)"
) => {
  const prefix = Array(repeat + 1).join(selector);

  const onProcessRule = (rule, sheet) => {
    const parent = rule.options.parent;

    if (
      sheet.options.increaseSpecificity === false ||
      rule.type !== "style" ||
      (parent && parent.type === "keyframes") ||
      rule.selectorText.includes(selector)
    )
      return;

    rule.selectorText = prefix + rule.selectorText;
  };

  return { onProcessRule: onProcessRule };
};

export default increaseSpecificity;
