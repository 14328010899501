// Utils/Hooks
import { useConversationMediator } from "@src/providers/ConversationMediatorProvider";

// Types/Constants
import { IPingResponse } from "@src/mediators/ChatClientApiAdapter/apiTypes";
import { useAsyncValue } from "@src/hex/use_async_value";

export function useClientEmail() {
  const chatManager = useConversationMediator();
  const campaignData = (
    useAsyncValue(chatManager.widgetConfigurationRunner) as IPingResponse
  )?.campaignData;

  return campaignData?.clientEmail ?? "";
}
