// General
import React, { useCallback, useEffect, useState } from "react";
import {
  Platform,
  KeyboardAvoidingView,
  ScrollView,
  ViewStyle,
  SafeAreaView,
  Keyboard,
} from "react-native";

// Components/Shared
import ChatBox from "@src/components/ChatBox";
import ChatButton from "@src/components/ChatButton";

// Utils/Hooks
import { conversationHasEnded } from "@src/utils";
import { useConversationMediator } from "@src/providers/ConversationMediatorProvider";
import { useConversationStatus } from "@src/hooks/useConversationStatus";
import { useWidgetHeight } from "@src/hooks/useWidgetHeight";
import { useAvailableScreenHeight } from "@src/hooks/useAvailableScreenHeight";

// Types/Constants
import { widgetSpacing } from "@src/constants";
import { useAsyncValue } from "@src/hex/use_async_value";

interface ChatProps {
  zIndex?: number;
  children?: React.ReactNode;
}

export const Chat = ({ zIndex }: ChatProps) => {
  const mediator = useConversationMediator();
  const conversationStatus = useConversationStatus();

  const chatIsExpanded = useAsyncValue(mediator.widgetIsExpanded);

  const availableScreenHeight = useAvailableScreenHeight();
  const { widgetHeight } = useWidgetHeight();
  const rootContainerShouldScroll =
    chatIsExpanded && widgetHeight > availableScreenHeight;

  const isAndroid = Platform.OS === "android";
  const isIos = Platform.OS === "ios";
  const isWeb = Platform.OS === "web";

  const [isKeyboardOpening, setIsKeyboardOpening] = useState(false);

  useEffect(() => {
    const keyboardWillShowListener = Keyboard.addListener(
      "keyboardWillShow",
      () => {
        setIsKeyboardOpening(true);
      }
    );

    const keyboardWillHideListener = Keyboard.addListener(
      "keyboardWillHide",
      () => {
        setIsKeyboardOpening(false);
      }
    );

    return () => {
      keyboardWillShowListener.remove();
      keyboardWillHideListener.remove();
    };
  }, []);

  // RN no longer says there is a preference between StyleSheet.create() and a plain ViewStyle object
  // https://stackoverflow.com/a/59349940

  const $rootViewStyle: ViewStyle = {
    flex: !rootContainerShouldScroll ? undefined : 1,
    justifyContent: "flex-end",
    alignItems: "flex-end",
    position: "absolute",
    // The z index for web is handled in src/App.tsx (@global #tcn-chat-root)
    zIndex: isWeb ? undefined : zIndex ?? 999,
    bottom: 0,
    right: 0,
    height:
      !isIos && !rootContainerShouldScroll
        ? undefined
        : isAndroid
        ? availableScreenHeight
        : isKeyboardOpening
        ? "100%"
        : "auto",
    backgroundColor: "transparent",
  };

  const $scrollViewStyle: ViewStyle = {
    height:
      isAndroid || !rootContainerShouldScroll
        ? undefined
        : availableScreenHeight,
  };

  const $scrollViewInnerStyle: ViewStyle = {
    flexGrow: 1,
    justifyContent: "flex-end",
    paddingTop: widgetSpacing.fromTop,
  };

  const handleShowChat = useCallback(() => {
    if (chatIsExpanded && conversationHasEnded(conversationStatus)) {
      // Reset the conversation status now that we are closing the widget and the conversation that has ended.
      mediator.resetAfterChatEnd();
    }
    mediator.expandWidget();
  }, [conversationStatus, chatIsExpanded]);

  return (
    <KeyboardAvoidingView
      keyboardVerticalOffset={0}
      style={$rootViewStyle}
      behavior="position"
    >
      <SafeAreaView
        pointerEvents="box-none"
        // The element ID for web is handled in index.web.tsx
        nativeID={isWeb ? undefined : "tcn-chat-root"}
      >
        <ScrollView
          nativeID="tcn-chat-wrapper"
          scrollEnabled={widgetHeight > availableScreenHeight}
          nestedScrollEnabled
          keyboardShouldPersistTaps="handled"
          // @ts-ignore - this is a valid prop for ScrollView and it is needed
          automaticallyAdjustKeyboardInsets
          style={$scrollViewStyle}
          contentContainerStyle={$scrollViewInnerStyle}
        >
          {chatIsExpanded ? (
            <ChatBox />
          ) : (
            <ChatButton handleClick={handleShowChat} />
          )}
        </ScrollView>
      </SafeAreaView>
    </KeyboardAvoidingView>
  );
};

export const ChatWrapper = ({ children }) => {
  const mediator = useConversationMediator();

  const widgetConfigError = useAsyncValue(mediator.fetchConfigurationError);

  if (widgetConfigError) {
    return null;
  }

  return children;
};

export default ChatWrapper;
