import { ResponseValues } from "@src/components/flows/flow_message/utils";

const nativePostMessage =
  "window.ReactNativeWebView.postMessage(JSON.stringify(values))";
const webPostMessage = "window.postMessage({ formValues: values })";
const setDocumentHeight = `
  const documentHeight = document.body.scrollHeight + 20;
  window.ReactNativeWebView.postMessage(JSON.stringify({ viewportHeight: documentHeight }));
  `;

export const scriptString = (platform?: string): string => {
  return `
      const forms = document.querySelectorAll("form");
      const formList = Array.from(forms);

      ${platform !== "web" && setDocumentHeight}

      formList.forEach(function(form) {
        form.addEventListener("submit", function(event) {
          event.preventDefault();

          // Check if there is actually a submitter button value
          const submitButtonValue = event.submitter.value ? { [event.submitter.name]: event.submitter.value } : undefined;

          // Create a FormData object from the form
          const formData = new FormData(this);

          let formValues = Object.fromEntries(formData.entries())

          // Set any checkboxes to a boolean value if they are checked
          Object.values(form.elements).forEach(function(el) {
            if (el.type === "checkbox") {
              formValues[el.name] = el.checked
            }
          })

          // Combine the event submit button (submitter) value if it has one
          const values = {
            ...formValues,
            ...submitButtonValue
          }

          ${platform === "web" ? webPostMessage : nativePostMessage}
        });
      });
      true;
    `;
};

export const messageResponseScript = (values: ResponseValues): string => {
  return `
  try {
    function isButton(node) {
      // Check if the node exists and is a button element
      return node && node.nodeName && node.nodeName.toUpperCase() === "BUTTON";
    }

    const processFormNodes = async () => {
      const formNodes = document.querySelectorAll("form");

      for (const formNode of formNodes) {
        await new Promise((resolve) => {
          Object.values(formNode.elements).forEach((element) => {
            const values = ${JSON.stringify(values)};

            if (element.type === "checkbox") {
              values[element.name] && element.setAttribute("checked", "true");
              element.setAttribute("disabled", "true");
            } else if (element.type === "radio") {
              values[element.name] && values[element.name] == element.value && element.setAttribute("checked", "true");
              element.setAttribute("disabled", "true");
            } else if (element.tagName === 'SELECT') {
              element.setAttribute("value", values[element.name]);
              element.setAttribute("disabled", "true");

              const options = element.querySelectorAll("option");
              options.forEach((option) => {
                if (option.getAttribute("value") === values[element.name]) {
                  option.setAttribute("selected", "true");
                }
              });
            } else if (element.tagName === 'INPUT'){
              element.setAttribute("value", values[element.name]);
              element.setAttribute("disabled", "true");
            }

            if (isButton(element) && !element.getAttribute("value")) {
              element.setAttribute("disabled", "true");
              return;
            }

            if (isButton(element)) {
              if (element.getAttribute("value") === "${
                Object.values(values)[0]
              }") {
                element.setAttribute("disabled", "true");
                element.setAttribute("class", "active");
              } else {
                element.remove();
              }
            // TODO: handle the form elements if there is more than just buttons
            }
          });
          // Resolve the promise after processing all elements in the formNode
          resolve();
        });
      }
    };
    processFormNodes().then(() => {
      ${setDocumentHeight}
    });
  } catch(e) {
    alert(e)
  }
    true;
    `;
};
