import React from "react";
import OmniChatClient from "./src/App";
import ReactDOM from "react-dom";

(function (config) {
  if (!(window as any).tcnChat) {
    const div = document.createElement("div");

    (window as any).tcnChat = {
      init: function init(config) {
        ReactDOM.render(
          <OmniChatClient config={config} />,
          document.getElementById("tcn-chat-root")
        );
      },
    };

    div.id = "tcn-chat-root";
    document.body.appendChild(div);
  }
})();

// const config = {
//   apiKey: "",
//   url: "chat.dev.tcn.com",
//   mainColor: "#669EB4",
//   headingColor: "#FFFFFF",
//   paragraphColor: "#262626",
// };
// ReactDOM.render(<OmniChatClient config={config} />, document.getElementById("root"));
